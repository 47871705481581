<template>
  <div>
    <van-nav-bar
      title="系统公告"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="mNotice" style="margin-bottom: 11px">
      <div v-for="(item, index) in listData" :key="index">
        <div
          class="item"
          v-for="(content, index) in item.contentList"
          :key="index"
          @click="toNoticeDetails(content)"
        >
          <div class="notice-header">
            <div class="name">
              <div class="text" style="color: rgb(0, 0, 0)">
                {{ content.contentTitle }}
              </div>
              <div class="time">{{ content.datelineCreateReadable }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryNewsList } from "@/api/news";

export default {
  data() {
    return {
      queryParam: {
        noticeType: "1",
      },
      listData: [],
    };
  },

  created() {
    this.initData();
  },
  methods: {
    /**
     * 查询首页公告
     */
    initData() {
      queryNewsList(this.queryParam).then((response) => {
        if (response.status == 0) {
          this.listData = response.result;
        }
      });
    },

    /**
     * 展示公告详情
     */
    toNoticeDetails(data) {
      this.$router.push({ name: "mNoticeDetails", params: { id: data.id } });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.mNotice {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.mNotice .item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 394px;
  border-radius: 5px;
  background: #fff;
  padding: 16px 11px;
  box-sizing: border-box;
  margin-top: 10px;
}

.mNotice .item .notice-header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 11px;
}

.mNotice .item .notice-header .name {
  flex: 1;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.mNotice .item .notice-header .name .text {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e2331;
}

.mNotice .item .notice-header .name .time {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9b9fa8;
}

.mNotice .item .title {
  width: 100%;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e2331;
  margin-top: 13px;
  white-space: pre-line;
}
</style>
