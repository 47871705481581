<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
      { 'border-radius': module.base.round + 'px' },
    ]"
  >
    <div :style="[{ 'border-radius': module.base.round + 'px' }]">
      <!-- 导航 -->
      <div class="classify_view">
        <van-grid :column-num="4" class="no-border-grid">
          <van-grid-item
            v-for="item in navLists"
            :key="item.id"
            @click="toProductList(item)"
          >
            <!-- 自定义图片 -->
            <!-- <img
              :src="item.prcUrl"
              alt="Custom Image"
              style="width: 40px; height: 40px; border-radius: 5px"
            /> -->
            <van-image
              round
              :src="item.prcUrl"
              style="width: 40px; height: 40px; border-radius: 5px"
            />

            <!-- 自定义文字 -->
            <div style="padding-top: 5px">
              {{ item.categoryName }}
            </div>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["module"],
  components: {},
  data() {
    return {
      navLists: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      indexBrandList: (state) => state.index.indexBrandList,
    }),
  },
  created() {
    //查询首页Nav导航商品
    this.getNavList();
  },

  methods: {
    //查询首页Nav导航商品
    getNavList() {
      let data = { offset: 0, limit: 10 };

      this.$store.dispatch("index/queryIndexBrandList", data).then(() => {
        this.navLists = this.indexBrandList.list;
        // this.$forceUpdate();
      });
    },

    /**
     * 跳转到商品列表
     */
    toProductList(value) {
      console.log(value);
      this.$router.push({
        path: "product_list",
        query: { categoryId: value.id, categoryName: value.categoryName },
      });
    },
  },
};
</script>

<style>
.classify_view {
  /* width: 347px; */
  /* height: 334rpx; */
  background-color: #fff;
}
</style>
