<template>
  <div style="background-color: #f5f7fa">
    <van-nav-bar
      left-text="返回"
      title="我的订单"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-search
      v-model="queryParam.keywordSearch"
      shape="round"
      show-action
      placeholder="请输入订单号/充值账号"
    >
      <template #action>
        <div @click="searchAction()">搜索</div>
      </template>
    </van-search>

    <van-tabs
      type="line"
      style="display: block"
      v-model="activeName"
      @click="changeTab($event)"
    >
      <van-tab
        :title="item.orderLabel"
        v-for="(item, key, index) in orderStatusList"
        :key="index"
        :name="item.statusDelivery"
      >
        <van-list
          v-model="loadingState"
          :finished="finished"
          finished-text="没有更多了"
          @load="loadMore"
        >
          <div style="padding: 5px 10px 10px 10px">
            <div
              class="scroll-item"
              v-for="(v, key, index) in orderLists"
              :key="index"
            >
              <div class="item-top">
                <div class="order-text">
                  订单号：{{ v.serialNum }}
                  <van-icon name="label" />
                </div>
                <span :style="{ color: v.statusTextColor }">
                  {{ v.statusText }}
                </span>
              </div>
              <div
                class="center"
                @click="toProductDetails(v.orderFormProduct.productId)"
              >
                <van-image
                  width="100"
                  height="100"
                  :src="v.orderFormProduct.picUrl"
                />

                <div class="center-right">
                  <span class="center-title">
                    <span>{{ v.orderFormProduct.productName }}</span>
                  </span>

                  <div class="center-num">
                    <span class="money" style="font-size: 14px"
                      >￥{{ v.orderFormProduct.productPrice }}</span
                    >

                    <span class="number">
                      <span>x{{ v.orderFormProduct.quantity }}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="order-bottom">
                <div class="order-bottom-left">
                  合计：<span>
                    <span>
                      <span class="money">
                        <span>{{ v.amountFinally }}</span>
                      </span>
                    </span>
                  </span>
                </div>
                <div class="order-bottom-right">
                  <!-- <div class="order-btn info" @click="queryOrderDetails(v)">
                    查看详情
                  </div> -->

                  <van-button
                    type="info"
                    round
                    size="small"
                    @click="queryOrderDetails(v)"
                    >查看详情</van-button
                  >

                  <div v-if="v.paid == true">
                    <van-button
                      type="primary"
                      round
                      size="small"
                      v-if="v.isRefund == false"
                      @click="applyShouHou(v)"
                      >申请售后</van-button
                    >

                    <!-- <div
                      style="cursor: pointer"
                      class="order-btn info"
                      v-if="v.isRefund == false"
                      @click="applyShouHou(v)"
                    >
                      申请售后
                    </div> -->

                    <!-- <div
                      @click="cuichu()"
                      class="order-btn info"
                      style="
                        background-color: #fff;
                        border: 0.0625rem solid #a0a3ac;
                        color: #a0a3ac;
                        cursor: pointer;
                      "
                      v-else
                    >
                      催促
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>

        <!-- <van-empty v-if="orderLists.length == 0" description="您还没有订单~" /> -->
      </van-tab>
    </van-tabs>

    <!-- 申请售后弹出框 -->
    <!-- 提交订单弹出框 -->
    <van-popup
      v-model="orderPopupStatus"
      closeable
      position="bottom"
      :style="{ height: '45%' }"
    >
      <div style="padding: 10px">
        <van-form>
          <van-field
            readonly
            clickable
            name="picker"
            :value="value"
            label="申请原因"
            placeholder="点击选择"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              value-key="label"
              show-toolbar
              :columns="options"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>

          <van-field
            v-model="desc"
            rows="3"
            autosize
            label="问题描述"
            type="textarea"
            placeholder="请输入"
            maxlength="50"
            show-word-limit
          />

          <div style="margin: 10px">
            <van-button round block type="info" @click="handleCreate()"
              >提交申请</van-button
            >
          </div>
        </van-form>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import "@/assets/css/style.css";
import { Toast } from "vant";

export default {
  name: "HelloWorld",
  data() {
    return {
      showPicker: false,
      orderPopupStatus: false,
      orderLists: [],
      card: [],
      cdKey: "",
      cdKeyStatus: false,
      orderActive: 0,
      dictList: [],
      orderList: [],
      queryParam: {
        keywordSearch: undefined,
        statusDelivery: undefined,
        pageNum: 1,
        pageSize: 10,
      },
      orderId: "",
      desc: "",
      value: "",
      code: "",
      productId: "",
      arr: [],
      options: [
        { value: 1, label: "充值没到账" },
        { value: 2, label: "其他问题" },
      ],

      loadingState: false,
      finished: false,
      isFetching: false, // 是否有正在进行中的请求

      orderStatusList: [
        {
          statusDelivery: "undefined",
          orderLabel: "全部",
        },
        {
          statusDelivery: "0",
          orderLabel: "等待处理",
        },
        {
          statusDelivery: "1",
          orderLabel: "正在处理",
        },
        {
          statusDelivery: "2",
          orderLabel: "交易成功",
        },
        {
          statusDelivery: "4",
          orderLabel: "已退款",
        },
      ],
      activeName: this.$route.query.statusDelivery,
    };
  },
  computed: {
    ...mapGetters({
      list: "orderList",
    }),
    ...mapState({
      list: (state) => state.order.orderList,
    }),
  },
  created() {
    document.title = "我的订单";
    this.queryParam.statusDelivery = this.$route.query.statusDelivery;

    //查询订单
    this.loadData();
  },
  methods: {
    /**
     * 搜索查询
     */
    searchAction() {
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.orderLists = [];
      this.loadData();
    },

    /**
     * 加载更多商品
     */
    loadMore() {
      if (this.isFetching) {
        // 如果有正在进行中的请求，则不继续发起新的请求
        return;
      }
      this.queryParam.pageNum = this.queryParam.pageNum + 1;
      this.loadingState = true;

      this.loadData();
    },

    /**
     * 初始化接口
     */
    loadData() {
      this.isFetching = true;
      this.$store.dispatch("order/list", this.queryParam).then(() => {
        this.orderLists = this.orderLists.concat(this.list);
        if (this.list.length == 0) {
          this.finished = true;
        }
        this.loadingState = false;
        this.isFetching = false;
      });
    },

    /**
     * 切换tab页
     */
    changeTab(statusDelivery) {
      if (statusDelivery === "undefined") {
        statusDelivery = undefined;
      }
      this.orderLists = [];
      // 更新查询参数
      this.queryParam.statusDelivery = statusDelivery;
      this.queryParam.pageNum = 1;
      // 重新加载数据
      this.loadData();
    },

    onConfirm(value) {
      console.log(value);
      this.value = value.label;
      this.code = value.value;
      this.showPicker = false;
    },

    /**
     * 申请售后催促
     */
    cuichu() {
      Toast("已加急处理");
    },
    // // 查询商品列表
    // getDictList() {
    //   this.queryParam.dictType = 'order_status'
    //   dictList(this.queryParam).then((response) => {
    //     this.dictList = response.data.rows
    //   })
    // },

    // queryOrderList(value, k){
    //   this.orderActive = k
    //   orderInfoByType().then((response) => {
    //     this.orderList = response.rows
    //   })
    // },

    // /**
    //  * 查询卡密
    //  */
    // queryCdkey(v){
    //       this.cdKeyStatus = true
    //       this.card = JSON.parse(v.card)
    //   // outerOrder(this.queryParam).then((response) => {
    //   //   //数据加载
    //   //   console.log(response.data.data)
    //   //   if(response.data.data!=''){
    //   //     this.cdKey = response.data.data
    //   //   }
    //   // })
    // },

    //  //复制卡号
    // cardCopy(){
    //       const inputNode = document.createElement('input')
    //       inputNode.setAttribute('readonly', 'readonly')
    //       inputNode.value = this.card.cardNo
    //       document.body.appendChild(inputNode)
    //       inputNode.select()
    //       document.execCommand('Copy')
    //       inputNode.className = 'oInput'
    //       inputNode.style.display = 'none'
    //       Toast('卡号复制成功')
    // },

    // //复制密码
    // passWordCopy(){
    //       const inputNode = document.createElement('input')
    //       inputNode.setAttribute('readonly', 'readonly')
    //       inputNode.value = this.card.cardPassword
    //       document.body.appendChild(inputNode)
    //       inputNode.select()
    //       document.execCommand('Copy')
    //       inputNode.className = 'oInput'
    //       inputNode.style.display = 'none'
    //       Toast('卡密复制成功')
    // },

    // //复制Ckd
    // copyCkd(){
    //    var copyInput = document.createElement('input')
    //   //val是要复制的内容
    //   copyInput.setAttribute('readonly', 'readonly')
    //   copyInput.setAttribute('value', this.cdKey)
    //   document.body.appendChild(copyInput)
    //   copyInput.select()
    //   try {
    //     var copyed = document.execCommand('copy')
    //     if (copyed) {
    //       document.body.removeChild(copyInput)
    //       Toast('复制成功')
    //     }
    //   } catch {
    //       Toast.fail('复制失败,请检查客服')
    //   }
    // },

    /**
     * 跳转到商品详情
     */
    toProductDetails(id) {
      this.$router.push({
        path: "product_detail",
        query: { product_id: id },
      });
    },

    // /**
    // 查询订单详情
    //  */
    queryOrderDetails(v) {
      console.log(v);
      this.$router.push({
        path: "/order_detail",
        query: {
          orderId: v.id,
        },
      });
    },

    /**
     * 申请订单售后
     */
    applyShouHou(v) {
      this.orderPopupStatus = true;
      this.orderId = v.id;
      this.arr = [
        {
          orderFormProductId: v.orderFormProduct.id,
          quantity: v.orderFormProduct.quantity,
        },
      ];
    },

    /**
     * 提交申请售后
     */
    handleCreate() {
      console.log(this.code);
      if (this.code == "") {
        Toast.fail({
          message: "请选择申请原因",
        });
        return;
      }

      if (this.desc == "") {
        Toast.fail({
          message: "请输入问题描述",
        });
        return;
      }

      this.$store
        .dispatch("refund/create", {
          orderFormProductList: this.arr,
          reasonType: this.code,
          reasionDescription: this.desc,
          id: this.orderId,
        })
        .then(() => {
          Toast.success({
            message: "提交成功",
          });
          this.orderPopupStatus = false;
        });
    },
    //  //跳转到首页
    // toIndex() {
    //   this.$router.push('/')
    // },
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>
<style>
.my-order {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 23.4375rem;
  height: 100vh;
  background-color: #fff;
  overflow: auto;
}

.my-order .content-box {
  width: 23.4375rem;
  background-color: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-order .scroll-box {
  width: 23.4375rem;
  height: calc(100vh - 6.25rem);
  position: relative;
  background: #f5f7fa;
  margin-top: 0.625rem;
}

.uni-scroll-div {
  position: relative;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.uni-scroll-div {
  position: relative;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.uni-scroll-div-content {
  width: 100%;
  height: 100%;
}

.my-order .scroll-box .scroll-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0 0.5625rem;
  gap: 0.9375rem;
}

.scroll-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  /* width: 22.3125rem; */
  /* height: 12.8125rem; */
  background: #ffffff;
  border-radius: 0.46875rem;
  padding: 0px 10px 15px 10px;
  margin-top: 10px;
}

.scroll-item .item-top {
  margin-top: 0.53125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.scroll-item .item-top .order-text {
  color: #9b9fa8;
  font-weight: 400;
  font-size: 0.75rem;
}

.copy-icon {
  width: 0.53125rem;
  height: 0.53125rem;
  position: relative;
  display: inline-block;
  border-color: #9b9fa8;
}

.copy-icon:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.09375rem;
  border: 0.0625rem solid;
  border-color: inherit;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #fff;
}

.copy-icon {
  width: 0.53125rem;
  height: 0.53125rem;
  position: relative;
  display: inline-block;
  border-color: #9b9fa8;
}

.scroll-item .item-top .link {
  color: #fe5f4d;
  font-weight: 600;
  font-size: 14px;
}

.scroll-item .center {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  width: 100%;
  gap: 0.625rem;
  margin-top: 1.09375rem;
}

.scroll-item .center .goods-img {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 0.46875rem;
}

.goods-img .goodsImg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

/* .goods-img img {
        -webkit-touch-callout: none;
        user-select: none;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    } */

.scroll-item .center .center-right {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.scroll-item .center .center-right .center-title {
  font-weight: 550;
  color: #1e2331;
  font-size: 0.8125rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 12.5rem;
}

.scroll-item .center .center-right .center-buy-info {
  margin-top: 0.71875rem;
  color: #9b9fa8;
  font-weight: 400;
  font-size: 0.75rem;
}

.scroll-item .center .center-right .center-num {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.375rem;
  color: #1e2331;
  font-weight: 400;
  font-size: 0.625rem;
}

.scroll-item .center .center-right .center-num .number {
  color: #9b9fa8;
  font-size: 0.8125rem;
}

.scroll-item .order-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.03125rem;
  height: 2rem;
}

.scroll-item .order-bottom .order-bottom-left {
  font-weight: 400;
  color: #1e2331;
  font-size: 0.875rem;
}

.scroll-item .order-bottom .order-bottom-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
}

.scroll-item .order-bottom .order-bottom-right .info {
  /* background-color: #fff;
  border: 0.0625rem solid #a0a3ac;
  color: #a0a3ac; */
  background-color: #e11919;
  border: 0.0625rem solid #a0a3ac;
  color: #ffffff;
}

.scroll-item .order-bottom .order-bottom-right .order-btn {
  width: 5.25rem;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 400;
}

.scroll-item .order-bottom .order-bottom-right .submit {
  color: #fff;
  background: linear-gradient(90deg, #fe8754 0%, #fe5f4d 100%);
}

.scroll-item .order-bottom .order-bottom-right .btn {
  width: 5.25rem;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 400;
}

.scroll-item .order-bottom .order-bottom-left .money {
  color: #fd4d20;
  font-weight: 550;
  font-size: 1.0625rem;
}
</style>
