<template>
  <div></div>
</template>
<script>
import { scanLogin } from "@/api/user";
import { setToken, setUserId } from "@/utils/auth";

export default {
  data() {
    return {
      queryParam: {
        pageNum: 1,
        pageSize: 10,
      },
      listData: [],
      selectedIndex: 0,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      //获取扫码登录code
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");
      const type = searchParams.get("type");

      const data = {
        code: code,
        type: type,
      };
      new Promise((resolve, rejust) => {
        scanLogin(data)
          .then((res) => {
            // this.domainList = res.result.list;
            if (res.status === 0) {
              if (res.result.loginStatus === 1) {
                //第三方登录ID
                const socialuid = res.result.socialuid;
                //登录类型
                const type = res.result.type;

                this.$router.push({
                  path: "scanLogin",
                  query: { socialuid: socialuid, type: type },
                });
              } else {
                const { token, user_id } = res.result;
                setToken(token);
                setUserId(user_id);
                this.$router.push("/");
              }
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },
  },
};
</script>
