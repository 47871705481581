var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px',
    },
  ])},[_c('van-row',[_c('van-col',{attrs:{"span":"24"}},[_c('div',[_c('div',[_c('div',{staticClass:"aui-flex"},[_c('span',{staticClass:"aui-flex-box"},[_c('van-icon',{attrs:{"name":"fire","size":"18","color":"#ee0a24"}}),_vm._v(" 推荐商品 ")],1),_c('div',{on:{"click":function($event){return _vm.tohotProduct()}}},[_c('van-icon',{attrs:{"name":"arrow"}})],1)]),_c('div',{staticClass:"recommend-grids"},[_c('div',{staticClass:"choice_list"},[_c('van-swipe',{staticClass:"recommend",attrs:{"autoplay":2000,"indicator-color":"white","width":90}},_vm._l((_vm.recommendLists),function(value,key,index){return _c('van-swipe-item',{key:index},[_c('div',{staticClass:"choice_item",on:{"click":function($event){return _vm.toProductDetails(value)}}},[_c('div',{staticClass:"choice_img_view"},[_c('van-image',{attrs:{"radius":"5px","src":value.picUrl}})],1),_c('div',{staticClass:"choice_name"},[_vm._v(_vm._s(value.productName))]),_c('div',{staticClass:"choice_price"},[_c('span',[_c('span',{staticClass:"choice_symbol"},[_vm._v("￥")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(value.price))])])])])])}),1)],1)])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }