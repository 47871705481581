<template>
  <div id="module">
    <section
      class="section-module"
      v-for="(element, index) in moduleList"
      :key="index"
    >
      <div
        class="xcx-module-item"
        :class="selectIndex === index ? 'on' : ''"
        @click.stop="moduleClick(index)"
        @mouseenter="mouseovers(index)"
        @mouseleave="mouseout"
      >
        <!-- 手机端 -->
        <!-- <mSearch :module="element" v-if="element.type == 101" /> -->
        <mBanner :module="element" v-if="element.type == 102" />
        <mNav :module="element" v-if="element.type == 103" />
        <mGoods :module="element" v-if="element.type == 104" />
        <mRecommend :module="element" v-if="element.type == 105" />
        <mNotice :module="element" v-if="element.type == 106" />
      </div>
    </section>
  </div>
</template>

<script>
import mNav from "@/pages/template/mobile/module/nav/common.vue";
import mGoods from "@/pages/template/mobile/module/goods/common.vue";
// import mSearch from "@/pages/template/mobile/module/search/common.vue";
import mBanner from "@/pages/template/mobile/module/banner/common.vue";
import mRecommend from "@/pages/template/mobile/module/recommend/common.vue";
import mNotice from "@/pages/template/mobile/module/Notice/common.vue";

export default {
  name: "Module",
  props: {
    moduleList: { type: Array },
    prev: { default: false },
  },
  components: {
    mNav,
    mGoods,
    mBanner,
    mRecommend,
    mNotice,
  },
  data() {
    return {
      placeholder: false,
      selectIndex: "", //选中
      activeIndex: "", //当前活动active
    };
  },

  methods: {
    //模块点击
    moduleClick(index) {
      this.selectIndex = index;
      this.$emit("moduleClick", index);
    },

    //鼠标移入模块
    mouseovers(index) {
      let top = event.target.getBoundingClientRect().top;
      this.$emit("moduleMouseovers", { top: top, index: index });
    },

    //鼠标离开模块
    mouseout() {
      this.$emit("moduleMouseout");
    },
  },
};
</script>
