<template>
  <div class="fz-container">
    <el-container>
      <div style="background-color: #ffffff; min-height: 100vh">
        <el-menu class="el-menu-vertical-demo" :collapse="isCollapse">
          <div
            style="
              position: relative;
              width: 100%;
              height: 50px;
              line-height: 50px;
              background: #fff;
              text-align: center;
              overflow: hidden;
              padding-left: 4px;
            "
          >
            <router-link key="expand" class="sidebar-logo-link" to="/vip">
              <img :src="webSetting.detail.webLogo" class="sidebar-logo" />
              <span class="sidebar-title"> 分站管理控制台 </span>
            </router-link>
          </div>

          <el-menu-item index="1" @click="toLink('/vip')">
            <i class="el-icon-menu"></i>
            <span slot="title">工作台</span>
          </el-menu-item>

          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>商城设置</span>
            </template>

            <el-menu-item index="2-1" @click="toLink('/vip/sys')">
              基本信息
            </el-menu-item>

            <el-menu-item index="2-2" @click="toLink('/vip/customer')">
              客服管理
            </el-menu-item>

            <el-menu-item index="2-4" @click="toLink('/vip/homeUp')">
              首页弹框
            </el-menu-item>

            <el-submenu index="2-5">
              <template slot="title">广告管理</template>
              <el-menu-item index="2-5-1" @click="toLink('/vip/banner/banner')">
                电脑版-轮播图
              </el-menu-item>
              <el-menu-item
                index="2-5-2"
                @click="toLink('/vip/banner/mBanner')"
              >
                手机版-轮播图
              </el-menu-item>
            </el-submenu>
          </el-submenu>

          <el-menu-item index="3" @click="toLink('/vip/product')">
            <i class="el-icon-goods"></i>
            <span slot="title">商品管理</span>
          </el-menu-item>

          <el-menu-item index="4" @click="toLink('/vip/order')">
            <i class="el-icon-document"></i>
            <span slot="title">订单管理</span>
          </el-menu-item>

          <el-menu-item index="5" @click="toLink('/vip/user')">
            <i class="el-icon-setting"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>

          <el-submenu index="6">
            <template slot="title">
              <i class="el-icon-bank-card"></i>
              <span>财务管理</span>
            </template>

            <el-menu-item index="2-1" @click="toLink('/vip/commissionDetail')">
              佣金明细
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>

      <el-container>
        <el-header style="text-align: right">
          <span v-if="isCollapse">
            <i class="el-icon-s-fold" @click="isCollapse = false">
              <span style="font-size: 16px; margin: 0px 5px">展开</span>
            </i>
          </span>

          <span v-else>
            <i class="el-icon-s-unfold" @click="isCollapse = true">
              <span style="font-size: 16px; margin: 0px 5px">收起</span>
            </i>
          </span>

          <span>
            <el-dropdown>
              <el-avatar :src="circleUrl"></el-avatar>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span @click="toReturn()">返回商城</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </el-header>

        <div style="padding: 10px">
          <router-view> </router-view>
        </div>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      isCollapse: false,
      breadList: [],
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  computed: {
    ...mapState({
      branchId: (state) => state.user.branchId,
      webSetting: (state) => state.index.webSetting,
    }),
  },
  created() {
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.$store.dispatch("user/info").then(() => {
          this.$nextTick(() => {
            this.verifyAuthority();
          });
        });
      }
    },

    /**
     * 验证权限
     */
    verifyAuthority() {
      if (!this.branchId) {
        this.$message({
          message: "权限不足",
          type: "warning",
        });
        this.$router.push("/");
      }
    },

    toReturn() {
      this.$router.push("/");
    },

    /**
     * 跳转路由
     */
    toLink(link) {
      this.$router.push(link).catch((err) => {
        // 在这里处理错误
        console.error("路由跳转出错：", err);
      });
    },
  },
};
</script>
<style scoped>
.fz-container {
  width: 100%;
  min-height: 100vh;
}
.el-header {
  font-size: 20px;
  background-color: #ffffff;
  -webkit-transition: -webkit-box-shadow linear 0.05s;
  border-color: rgba(25, 39, 142, 0.5);
  -webkit-box-shadow: 1px 0 1px #c0c0c0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-nav-header {
  color: black;
  height: 58px;
  line-height: 58px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.icon-box {
  float: left;
  width: 30px;
  text-align: center;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 230px;
}

.sidebar-title {
  display: inline-block;
  margin: 0;
  color: #000000;
  font-weight: 600;
  line-height: 50px;
  font-size: 20px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
  margin-left: 5px;
}

.sidebar-logo {
  width: 30px;
  height: 30px;
  border-radius: 2px;
}
</style>
