import request from '@/utils/request'


// 添加商品
export function queryConvertProduct(data) {
    return request({
        url: '/api/convert/query/productCdk',
        method: 'post',
        data
    })
}



//确认兑换
export function confirmConvert(data) {
    return request({
        url: '/api/convert/confirm/convert',
        method: 'post',
        data
    })
}