<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <div class="page-header">
          <i class="page-header-title" style="margin-right: 16px"></i>售后列表
          <!-- <el-button style="float: right; padding: 3px 0" type="text"
            >操作按钮</el-button
          > -->
        </div>
      </div>

      <el-table :data="lists" border style="width: 100%">
        <el-table-column
          prop="orderFormSerialNum"
          label="订单号"
          align="center"
          width="250"
        >
        </el-table-column>

        <el-table-column label="商品名称" align="center" width="250">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.orderFormRefundProductList"
              :key="index"
            >
              {{ item.productName }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="商品价格" align="center">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.orderFormRefundProductList"
              :key="index"
            >
              {{ item.productPriceDeal }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="售后金额" align="center">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.orderFormRefundProductList"
              :key="index"
            >
              <el-tag type="danger" effect="plain" size="small">
                {{ item.amountRefund }}
              </el-tag>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="datelineCreateReadable"
          label="申请时间"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="statusText" label="处理状态" align="center">
        </el-table-column>

        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="toServiceDetail(scope.row.id)"
            >
              售后详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- <div
        v-for="(item, index) in lists"
        :key="index"
        class="bought-table-mod__table___AnaXt bought-wrapper-mod__table___3xFFM"
      >
        <colgroup>
          <col style="width: 30%" />
          <col style="width: 18%" />
          <col style="width: 18%" />
          <col style="width: 18%" />
          <col style="width: 18%" />
        </colgroup>
        <tbody class="bought-wrapper-mod__head___2vnqo">
          <tr>
            <td class="bought-wrapper-mod__head-info-cell___29cDO">
              <label style="padding-left: 10px">
                <span>{{ item.datelineCreateReadable }}</span>
              </label>
              <span style="padding-left: 10px">
                <span>订单号: </span>
                <span>{{ item.orderFormSerialNum }}</span>
              </span>
            </td>
            <td
              colspan="2"
              class="bought-wrapper-mod__seller-container___3dAK3"
            >
              <span class="seller-mod__container___zFAFV"></span>
            </td>
            <td>
              <span id="webww1"></span>
            </td>
            <td
              colspan="3"
              class="bought-wrapper-mod__thead-operations-container___2LwDA"
            >
              <span></span>
            </td>
          </tr>
        </tbody>
        <tbody style="border: 1px solid #dcdcdc; border-top: none">
          <tr>
            <td
              class="sol-mod__no-br___36X3g"
              v-for="(item, index) in item.orderFormRefundProductList"
              :key="index"
            >
              <div
                class="ml-mod__container___2DOxT production-mod__production___123Ax suborder-mod__production___3WebF"
              >
                <div class="ml-mod__media___2sZrj" style="width: 80px">
                  <a class="production-mod__pic___G8alD" target="_blank">
                    <el-image :src="item.picUrl" :fit="fit"></el-image>
                   
                  </a>
                </div>
                <div style="margin-left: 90px">
                  <a target="_blank"
                    ><span style="line-height: 16px">{{
                      item.productName
                    }}</span></a
                  >

                </div>
              </div>
            </td>
            <td class="sol-mod__no-br___36X3g">
              <div
                class="price-mod__price___3_8Zs"
                v-for="(item, index) in item.orderFormRefundProductList"
                :key="index"
              >
                <p>
                  <strong
                    ><span>￥</span
                    ><span>{{ item.productPriceDeal }}</span></strong
                  >
                </p>
                <p style="color: #6c6c6c; font-family: verdana">
                  <span>(商品价格)</span>
                </p>
              </div>
            </td>

            <td class="">
            <div>
              <div class="price-mod__price___3_8Zs">
                <p>
                  <strong><span>1</span></strong>
                </p>
              </div>
              <p style="color: #6c6c6c; font-family: verdana">
                <span>(购买数量)</span>
              </p>
            </div>
          </td>

            <td class="">
              <div
                v-for="(item, index) in item.orderFormRefundProductList"
                :key="index"
              >
                <div class="price-mod__price___3_8Zs">
                  <p>
                    <strong
                      ><span>￥</span
                      ><span>{{ item.amountRefund }}</span></strong
                    >
                  </p>
                </div>
                <p style="color: #6c6c6c; font-family: verdana">
                  <span>(申请退款金额)</span>
                </p>
              </div>
            </td>
            <td class="">
              <div>
                <p style="margin-bottom: 3px">
                  <el-tag
                    size="small"
                    type="success"
                    effect="dark"
                    v-if="item.statusText == '完成'"
                    >{{ item.statusText }}</el-tag
                  >

                  <el-tag size="small" effect="dark" v-else>{{
                    item.statusText
                  }}</el-tag>
                </p>
              </div>
            </td>
            <td class="">
              <div>
                <p>
                  <el-button
                    type="text"
                    size="small"
                    @click="toServiceDetail(item.id)"
                  >
                    售后详情
                  </el-button>

                 
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </div> -->

      <!-- 订单为空提示 -->
      <el-empty
        v-if="!lists || lists.length < 1"
        description="您还没有售后订单~"
      ></el-empty>

      <!-- 订单分页 -->
      <div style="float: right">
        <pagination
          :total="total"
          :current-page="pageNum"
          :page-size="pageSize"
          @currentChange="handleCurrentChange"
          @editPagesizes="editPageSizes"
        />
      </div>

      <SeriveDetail
        :afterOrderId="orderId"
        v-if="dialogVisible"
        @close="clickClose"
      />
    </el-card>
  </div>
</template>
<script>
// import Order from "@/components/orders";
// import Pagination from "@/components/Pagination";
import { mapState } from "vuex";
import SeriveDetail from "@/pages/pc/seriveDetail";

export default {
  data() {
    return {
      activeName: "first",
      number: [1, 1, 1, 1],
      lists: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      orderId: null,
    };
  },
  computed: {
    ...mapState({
      list: (state) => state.refund.refundList,
      refundTotal: (state) => state.refund.refundTotal,
    }),
  },
  components: {
    SeriveDetail,
    // Order,
    // Pagination,
  },
  created() {
    // this.loadData({ item: 0, number: 1 });
    this.loadData();
  },
  methods: {
    // handleClick(tab) {
    //   this.loadData({ item: tab.$attrs.item, number: 1 });
    // },
    // changeNumber(value) {
    //   this.loadData(value);
    // },
    loadData() {
      // this.number[value.item] = value.number;
      let data = { pageNum: this.pageNum, pageSize: this.pageSize };

      // if (value.item == 1) {
      //   data.isApplied = true;
      // }
      // if (value.item == 2) {
      //   data.isShippedOrWaitShipping = true;
      // }
      // if (value.item == 3) {
      //   data.isDone = true;
      // }
      this.$store.dispatch("refund/list", data).then(() => {
        this.lists = this.list;
        console.log(this.lists);
        this.total = this.refundTotal;
      });
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.loadData();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.pageSize = val;
      this.loadData();
    },

    /**
     * 售后详情
     */
    toServiceDetail(id) {
      console.log(this.dialogVisible);
      this.orderId = id;
      this.dialogVisible = !this.dialogVisible;
    },

    /**
     * 关闭售后详情弹框
     */
    clickClose() {
      console.log("-==============================================-");
      this.dialogVisible = !this.dialogVisible;
    },
  },
};
</script>
<style scoped>
/* 订单css */

.color_b {
  color: #2987e6 !important;
}

/* 订单 */

.seller-mod__container___zFAFV {
  white-space: nowrap;
}

.ml-mod__container___2DOxT:after {
  display: table;
  content: " ";
  font-size: 0;
  height: 0;
  clear: both;
}

.ml-mod__media___2sZrj {
  float: left;
}

.production-mod__production___123Ax {
  text-align: left;
}

.production-mod__pic___G8alD {
  display: block;
  overflow: hidden;
}

.production-mod__pic___G8alD img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100px;
  object-fit: cover;
}

.production-mod__sku-item___395gE {
  color: #9e9e9e;
  margin-right: 8px;
  line-height: 16px;
}

.price-mod__price___3_8Zs {
  font-family: verdana;
  font-style: normal;
  font-weight: bold;
}

.bought-table-mod {
  display: grid;
  grid-template-columns: 38% 10% 10% 12% 12% 11% 12%;
}

.bought-table-mod__table___AnaXt {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: #3c3c3c;
  table-layout: fixed;
  padding-bottom: 10px;
}

.bought-table-mod__table___AnaXt a {
  color: #3c3c3c;
  text-decoration: none;
  word-break: break-all;
}

.bought-table-mod__table___AnaXt p {
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 1;
}

.bought-table-mod__table___AnaXt td,
.bought-table-mod__table___AnaXt th {
  padding-top: 10px;
  padding-bottom: 10px;
}

.bought-table-mod__table___AnaXt th {
  font-weight: 400;
}

.bought-table-mod__col2___224Oh {
  width: 10%;
}

.bought-table-mod__col3___J0oe0 {
  width: 10%;
}

.bought-table-mod__col4___XvKTC,
.bought-table-mod__col5___2kktP {
  width: 12%;
}

.bought-table-mod__col6___1KqCQ {
  width: 11%;
}

.bought-table-mod__col7___PpB_p {
  width: 12%;
}

.bought-wrapper-mod__table___3xFFM .bought-wrapper-mod__head___2vnqo {
  background: #eaf8ff;
}

.bought-wrapper-mod__table___3xFFM .bought-wrapper-mod__head___2vnqo td {
  border: none;
}

.bought-wrapper-mod__table___3xFFM
  .bought-wrapper-mod__head___2vnqo
  .bought-wrapper-mod__head-info-cell___29cDO {
  text-align: left;
}

.bought-wrapper-mod__table___3xFFM td {
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: #daf3ff;
  text-align: center;
}

.bought-wrapper-mod__table___3xFFM .bought-wrapper-mod__no-br___gr7vV,
.bought-wrapper-mod__table___3xFFM td:last-child {
  border-right: none;
}

.bought-wrapper-mod__table___3xFFM .bought-wrapper-mod__no-bt___1e-OX {
  border-top: none;
}

.bought-wrapper-mod__checkbox-label___3Va60 {
  margin-left: 20px;
}

.bought-wrapper-mod__create-time___yNWVS {
  margin-right: 10px;
  font-weight: 700;
}

.bought-wrapper-mod__table___3xFFM
  .bought-wrapper-mod__thead-operations-container___2LwDA {
  padding-right: 20px;
  text-align: right;
}

.bought-wrapper-mod__seller-container___3dAK3 {
  padding: 10px 20px;
  text-align: center;
}

.sol-mod__no-br___36X3g {
  border-right: none !important;
}

.suborder-mod__production___3WebF {
  margin-left: 20px;
}

.suborder-mod__production___3WebF a:hover {
  color: #ff4200;
}

/* .bought-table-mod__table___AnaXt td {
  vertical-align: top;
} */
</style>
