<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
    ]"
  >
    <van-row>
      <van-col span="24">
        <div>
          <div>
            <div class="aui-flex">
              <span class="aui-flex-box">
                <van-icon name="fire" size="18" color="#ee0a24" /> 推荐商品
              </span>
              <div @click="tohotProduct()">
                <van-icon name="arrow" />
              </div>
            </div>
            <div class="recommend-grids">
              <div class="choice_list">
                <van-swipe
                  class="recommend"
                  :autoplay="2000"
                  indicator-color="white"
                  :width="90"
                >
                  <van-swipe-item
                    v-for="(value, key, index) in recommendLists"
                    :key="index"
                  >
                    <div class="choice_item" @click="toProductDetails(value)">
                      <div class="choice_img_view">
                        <van-image radius="5px" :src="value.picUrl" />
                      </div>
                      <div class="choice_name">{{ value.productName }}</div>
                      <div class="choice_price">
                        <span>
                          <span class="choice_symbol">￥</span>
                          <span style="font-size: 14px">{{ value.price }}</span>
                        </span>
                      </div>
                    </div>
                  </van-swipe-item>
                </van-swipe>
              </div>
            </div>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      recommendLists: [],
      hotLists: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      recommendList: (state) => state.index.recommendList,
      hotList: (state) => state.index.hotList,
    }),
  },
  created() {
    //初始化
    this.loadData();
    console.log("===============================");
  },
  methods: {
    loadData() {
      let data = { offset: 0, limit: 10 };

      //前台获取推荐产品列
      this.$store.dispatch("index/queryRecommendList", data).then(() => {
        this.recommendLists = this.recommendList;
      });

      //前台获取热卖产品列表
      this.$store.dispatch("index/queryHotList", data).then(() => {
        this.hotLists = this.hotList;
      });
    },

    //跳转商品详情界面
    toProductDetails(v) {
      this.$router.push({
        path: "product_detail",
        query: { product_id: v.id },
      });
    },

    /**
     * 跳转到热门商品列表页面
     */
    tohotProduct() {
      this.$router.push("/hotProduct");
    },
  },
};
</script>

<style scoped>
/* 推荐框 */
.recommend .van-swipe-item {
  color: #fff;
  font-size: 20px;
  /* line-height: 150px; */
  /* text-align: center; */
  /* background-color: #39a9ed; */
}

.reco_gd {
  position: relative;
  font-size: 16px;
}

.reco_gd a {
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  /* gap: 0.375rem; */
}

.recommend-grids {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* margin-top: 15px; */
  background-color: #ffffff;
}

.choice_list {
  /* width: 161px; */
  margin: 10px 10px 10px 10px;
}
.choice_item {
  max-width: 80px;
}
.choice_img_view {
  /* width: 80px; */
  /* height: 71px; */
  width: 80%;
  background-color: #f7f7f7;
  border-radius: 50px;
  /* padding-top: 17px; */
  box-sizing: border-box;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.choice_name {
  /* width: 80px; */
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* margin-left: -6rpx; */
  margin-top: 4px;
  text-align: center;
  color: black;
}
.choice_price {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #ff1c2f;
  margin-top: 4px;
  /* text-align: center; */
}
.choice_symbol {
  font-size: 12px;
}

.choice_title {
  height: 20px;
  font-size: 14px;
  color: #323232;
  margin-left: 11px;
  font-weight: 700;
}
.choice_title_text {
  /* padding: 5px 0px 0px 0px; */
  margin-left: 1px;
}
.choice_title_text > span {
  font-size: 10px;
  color: #fff;
  font-weight: 400;
}

.recommend_view {
  /* width: 347px; */
  background-color: #fff;
  border-radius: 6px;
  margin: 1px auto 0;
  box-sizing: border-box;
}
.recommend_title {
  font-size: 13px;
  color: #1e1e1e;
  padding-left: 18px;
  padding-top: 13px;
}
.recommend_con {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 10px;
  margin-top: 20px;
}
.recommend_item {
  max-width: 25%;
  width: 25%;
  font-size: 13px;
  color: #545454;
  padding-bottom: 22px;
}
.recommend_img {
  width: 41px;
  height: 41px;
  margin-bottom: 6px;
}

.image-view-area,
.image-view-view {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.image-view-img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
}

.column {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.between {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
uni-page-head {
  display: none;
}

.more_title {
  font-size: 15px;
  color: #838383;
}
</style>
