var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px',
    },
  ])},[_c('div',[_c('div',[_c('div',{staticClass:"aui-flex"},[_c('span',{staticClass:"aui-flex-box"},[_c('van-icon',{attrs:{"name":"fire","size":"18","color":"#ee0a24"}}),_vm._v(" 推荐商品 ")],1),_c('div',{on:{"click":function($event){return _vm.tohotProduct()}}},[_c('van-icon',{attrs:{"name":"arrow"}})],1)])])]),_c('div',{staticClass:"flex-shrink-0 has-footer"},[_c('div',[_c('div',[_c('div',[_c('div',{staticClass:"kind-show goods-list"},[_c('div',[_c('div',[_c('div',[_c('div',{staticClass:"goods-content"},_vm._l((_vm.listData),function(v,k,i){return _c('div',{key:i,staticClass:"goods-item",attrs:{"title":v.productName},on:{"click":function($event){return _vm.toProductDetails(v)}}},[_c('div',{staticClass:"goods-img"},[_c('el-image',{staticStyle:{"background-position":"0% 0%","background-size":"100% 100%"},attrs:{"src":v.picUrl}})],1),_c('div',{staticClass:"product_centent"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(v.productName))]),_c('span',[(
                              v.productType === '1' || v.productType === '2'
                            )?_c('van-tag',{attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(v.productTypeText)+" ")]):_c('van-tag',{attrs:{"type":"primary","color":"#00D25A"}},[_vm._v(" "+_vm._s(v.productTypeText)+" ")])],1),_c('div',{staticClass:"fun"},[_c('span',{staticStyle:{"font-size":"16px","font-weight":"500","color":"#f84d43"}},[_vm._v("￥"+_vm._s(v.price))])])])])}),0)])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }