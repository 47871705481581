<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <div class="page-header">
          <i class="page-header-title" style="margin-right: 16px"></i>我的订单
          <!-- <el-button style="float: right; padding: 3px 0" type="text"
            >操作按钮</el-button
          > -->
        </div>
      </div>

      <el-form label-width="80px" :inline="true">
        <el-form-item label="订单号">
          <el-input
            v-model="queryParams.orderNum"
            placeholder="请输入订单号"
          ></el-input>
        </el-form-item>

        <el-form-item label="充值账号">
          <el-input
            v-model="queryParams.rechargeAccount"
            placeholder="请输入充值账号"
          ></el-input>
        </el-form-item>

        <!-- <el-col :span="6"> -->
        <el-form-item>
          <el-button type="primary" @click="queryCriteria()" size="small"
            >查询</el-button
          >
        </el-form-item>
        <!-- </el-col> -->
      </el-form>

      <div>
        <div
          v-for="(item, index) in lists"
          :key="index"
          class="bought-table-mod__table___AnaXt bought-wrapper-mod__table___3xFFM"
        >
          <colgroup>
            <col style="width: 30%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
          </colgroup>
          <tbody class="bought-wrapper-mod__head___2vnqo">
            <tr>
              <td class="bought-wrapper-mod__head-info-cell___29cDO">
                <span style="margin-left: 10px; font-weight: 700">
                  订单号：{{ item.serialNum }}
                </span>
                <span style="margin-left: 10px">
                  下单时间：{{ item.datelineCreateReadable }}
                </span>
              </td>
              <td colspan="2"></td>

              <td colspan="3"></td>
            </tr>
          </tbody>
          <tbody style="border: 1px solid #dcdcdc; border-top: none">
            <tr>
              <td
                class="sol-mod__no-br___36X3g"
                @click="toProductDetails(item.orderFormProduct.productId)"
              >
                <div
                  class="ml-mod__container___2DOxT production-mod__production___123Ax suborder-mod__production___3WebF"
                >
                  <div class="ml-mod__media___2sZrj" style="width: 80px">
                    <a class="production-mod__pic___G8alD" target="_blank">
                      <el-image :src="item.orderFormProduct.picUrl"> </el-image>
                    </a>
                  </div>
                  <div style="margin-left: 90px">
                    <p>
                      <a target="_blank">
                        <span style="line-height: 16px">
                          {{ item.orderFormProduct.productName }}
                        </span>
                      </a>
                    </p>
                    <p>
                      <span>
                        <el-tag
                          effect="dark"
                          size="small"
                          :color="item.orderFormProduct.productTypeColor"
                        >
                          {{ item.orderFormProduct.productTypeText }}
                        </el-tag>
                      </span>
                    </p>
                  </div>
                </div>
              </td>
              <td class="sol-mod__no-br___36X3g">
                <div class="price-mod__price___3_8Zs">
                  <p>
                    <strong
                      ><span>￥</span
                      ><span>{{
                        item.orderFormProduct.productPrice
                      }}</span></strong
                    >
                  </p>
                  <p style="color: #6c6c6c; font-family: verdana">
                    <span>(商品单价)</span>
                  </p>
                </div>
              </td>

              <td class="">
                <div>
                  <div class="price-mod__price___3_8Zs">
                    <p>
                      <strong
                        ><span>{{
                          item.orderFormProduct.quantity
                        }}</span></strong
                      >
                    </p>
                  </div>
                  <p style="color: #6c6c6c; font-family: verdana">
                    <span>(购买数量)</span>
                  </p>
                </div>
              </td>

              <td class="">
                <div>
                  <div class="price-mod__price___3_8Zs">
                    <p>
                      <strong
                        ><span>￥</span
                        ><span>{{ item.amountFinally }}</span></strong
                      >
                    </p>
                  </div>
                  <p style="color: #6c6c6c; font-family: verdana">
                    <span>(订单价格)</span>
                  </p>
                </div>
              </td>
              <td class="">
                <div>
                  <p style="margin-bottom: 3px">
                    <el-tag effect="dark" :color="item.statusTextColor">{{
                      item.statusText
                    }}</el-tag>
                  </p>
                </div>
              </td>
              <td class="">
                <div>
                  <p>
                    <el-button
                      @click="orderDetails(item.id, item)"
                      type="text"
                      size="small"
                      >订单详情</el-button
                    >
                  </p>

                  <p>
                    <el-button
                      @click="applySh(item.id)"
                      v-if="item.paid"
                      type="text"
                      size="small"
                    >
                      申请售后
                    </el-button>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </div>
      </div>
      <!-- 订单为空提示 -->
      <el-empty
        v-if="!lists || lists.length < 1"
        description="您还没有订单~"
      ></el-empty>

      <!-- 分页 -->
      <div style="float: right">
        <pagination
          :total="total"
          :current-page="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          @currentChange="handleCurrentChange"
          @editPagesizes="editPageSizes"
        />
      </div>

      <!-- 订单详情弹框 -->
      <el-dialog
        title="订单详情"
        :visible.sync="orderDetailsDialog"
        append-to-body
      >
        <el-divider />

        <template v-if="this.orderDetailsObj.orderFormProduct">
          <el-descriptions title="商品信息" :column="3" border>
            <el-descriptions-item>
              <template slot="label"> 商品名称 </template>
              <template>
                {{ orderDetailsObj.orderFormProduct.productName }}
              </template>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 商品金额 </template>

              <template>
                ￥{{ orderDetailsObj.orderFormProduct.productPriceDeal }}
              </template>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 商品类型 </template>
              <template>
                <el-tag
                  effect="dark"
                  size="small"
                  :color="orderDetailsObj.orderFormProduct.productTypeColor"
                >
                  {{ orderDetailsObj.orderFormProduct.productTypeText }}
                </el-tag>
              </template>
            </el-descriptions-item>
          </el-descriptions>

          <div>
            <div>
              <el-descriptions
                title="提取卡密"
                :column="3"
                border
                v-for="(item, index) in this.orderDetailsObj.cardsList"
                :key="index"
              >
                <el-descriptions-item v-if="item.cardNum">
                  <template slot="label"> 卡号</template>
                  {{ item.cardNum }}
                </el-descriptions-item>
                <el-descriptions-item v-if="item.cardPwd">
                  <template slot="label"> 卡密</template>
                  {{ item.cardPwd }}
                </el-descriptions-item>
                <el-descriptions-item v-if="item.link">
                  <template slot="label"> 兑换地址</template>
                  {{ item.link }}
                </el-descriptions-item>
                <el-descriptions-item v-if="item.endTime">
                  <template slot="label"> 过期时间</template>
                  {{ item.endTime }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
            <el-divider />

            <div>
              <el-descriptions title="充值账号" :column="3" border>
                <el-descriptions-item
                  v-for="(item, index) in orderDetailsObj.orderParams"
                  :key="index"
                >
                  <template slot="label"> {{ item.name }} </template>
                  <span>
                    {{ item.value }}
                  </span>
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>

          <el-divider />

          <el-descriptions title="订单信息" :column="3" border>
            <el-descriptions-item>
              <template slot="label"> 订单编号 </template>
              {{ orderDetailsObj.serialNum }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 提交时间 </template>
              {{ orderDetailsObj.datelineCreateReadable }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 订单状态 </template>

              <el-tag
                size="medium"
                effect="dark"
                :color="orderDetailsObj.statusTextColor"
              >
                {{ orderDetailsObj.statusText }}
              </el-tag>
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label"> 订单金额 </template>
              ￥{{ orderDetailsObj.amountFinally }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label"> 支付金额 </template>
              ￥{{ orderDetailsObj.amountFinally }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label"> 下单数量</template>
              {{ orderDetailsObj.orderFormProduct.quantity }}
            </el-descriptions-item>

            <el-descriptions-item v-if="orderDetailsObj.startNum">
              <template slot="label"> 开始数量</template>
              {{ orderDetailsObj.startNum }}
            </el-descriptions-item>

            <el-descriptions-item v-if="orderDetailsObj.nowNum">
              <template slot="label"> 当前数量</template>
              {{ orderDetailsObj.nowNum }}
            </el-descriptions-item>

            <el-descriptions-item :span="3">
              <template slot="label"> 订单备注: </template>
              {{ orderDetailsObj.deliveryRemark }}
            </el-descriptions-item>

            <el-descriptions-item :span="3">
              <template slot="label"> 充值信息: </template>
              {{ orderDetailsObj.sellerRemark }}
            </el-descriptions-item>
          </el-descriptions>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click="orderDetailsDialog = false">关 闭</el-button>
        </div>
      </el-dialog>

      <Service
        :afterOrderId="orderId"
        v-if="dialogVisible"
        @close="clickClose"
      />
    </el-card>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { mapGetters, mapState } from "vuex";
// import Order from "@/components/order";
import Service from "@/pages/pc/serive-1";
import { detail } from "@/api/order";

export default {
  components: {
    // Order,
    Service,
    Pagination,
  },
  data() {
    return {
      CS: {
        "text-align": "center", //文本居中
        width: "80%",
        "word-break": "break-all", //过长时自动换行
      },
      LS: {
        //  color: '#000',文字的颜色
        "text-align": "center",
        height: "40px",
      },

      orderId: null,
      dialogVisible: false,
      orderDetailsObj: {},
      orderDetailsDialog: false,
      activeName: "first",
      number: [1, 1, 1, 1],
      lists: [],
      reqData: {},
      // 查询参数
      queryParams: {
        rechargeAccount: undefined,
        orderNum: undefined,
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      list: "orderList",
    }),
    ...mapState({
      isUpdate: (state) => state.order.isUpdate,
      orderDetail: (state) => state.order.orderDetail,
      orderTotal: (state) => state.order.orderTotal,
    }),
  },
  watch: {
    isUpdate() {
      this.loadData(this.reqData);
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("order/list", this.queryParams).then(() => {
        this.lists = this.list;
        this.total = this.orderTotal;
        // this.$forceUpdate();
      });
    },

    /**
     * 条件查询
     */
    queryCriteria() {
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = 10;
      this.loadData();
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum;
      this.loadData();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParams.pageSize = val;
      this.loadData();
    },

    //订单详情
    orderDetails(index, row) {
      //主站商品列表
      new Promise((resolve, rejust) => {
        let data = {
          id: row.id,
        };
        detail(data)
          .then((res) => {
            this.orderDetailsObj = res.result;
            this.orderDetailsDialog = true;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 确认收货
     */
    confirmReceived(id) {
      this.$store.dispatch("order/confirmReceived", { id: id });
      // this.$forceUpdate();
    },

    /**
     * 申请售后
     */
    applySh(id) {
      this.orderId = id;
      this.dialogVisible = !this.dialogVisible;
      // this.$router.push({ path: "serive_detail", query: { orderid: id } });
      // this.$forceUpdate();
    },

    /**
     * 跳转到商品详情
     */
    toProductDetails(id) {
      this.$router.push({
        path: "pgoodsDetail",
        query: { product_id: id },
      });
    },

    /**
     * 关闭售后详情弹框
     */
    clickClose() {
      console.log("-==============================================-");
      this.dialogVisible = !this.dialogVisible;
    },
  },
};
</script>
<style scoped>
/* 订单css */

.color_b {
  color: #2987e6 !important;
}

/* 订单 */

.seller-mod__container___zFAFV {
  white-space: nowrap;
}

.ml-mod__container___2DOxT:after {
  display: table;
  content: " ";
  font-size: 0;
  height: 0;
  clear: both;
}

.ml-mod__media___2sZrj {
  float: left;
}

.production-mod__production___123Ax {
  text-align: left;
}

.production-mod__pic___G8alD {
  display: block;
  overflow: hidden;
}

.production-mod__pic___G8alD img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.order_product_type {
  color: #9e9e9e;
  margin-right: 8px;
  line-height: 16px;
}

.price-mod__price___3_8Zs {
  font-family: verdana;
  font-style: normal;
}

.bought-table-mod__table___AnaXt {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: #3c3c3c;
  table-layout: fixed;
  padding-bottom: 10px;
}

.bought-table-mod__table___AnaXt a {
  color: #3c3c3c;
  text-decoration: none;
  word-break: break-all;
}

.bought-table-mod__table___AnaXt p {
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 1;
}

.bought-table-mod__table___AnaXt td,
.bought-table-mod__table___AnaXt th {
  padding-top: 10px;
  padding-bottom: 10px;
}

.bought-table-mod__table___AnaXt th {
  font-weight: 400;
}

.bought-table-mod__col1___3U5RK {
  width: 38%;
}

.bought-table-mod__col2___224Oh {
  width: 10%;
}

.bought-table-mod__col3___J0oe0 {
  width: 10%;
}

.bought-table-mod__col4___XvKTC,
.bought-table-mod__col5___2kktP {
  width: 12%;
}

.bought-table-mod__col6___1KqCQ {
  width: 11%;
}

.bought-table-mod__col7___PpB_p {
  width: 12%;
}

.bought-wrapper-mod__table___3xFFM .bought-wrapper-mod__head___2vnqo {
  background: #eaf8ff;
}

.bought-wrapper-mod__table___3xFFM .bought-wrapper-mod__head___2vnqo td {
  border: none;
}

.bought-wrapper-mod__table___3xFFM
  .bought-wrapper-mod__head___2vnqo
  .bought-wrapper-mod__head-info-cell___29cDO {
  text-align: left;
}

.bought-wrapper-mod__table___3xFFM td {
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: #daf3ff;
  text-align: center;
}

.bought-wrapper-mod__table___3xFFM .bought-wrapper-mod__no-br___gr7vV,
.bought-wrapper-mod__table___3xFFM td:last-child {
  border-right: none;
}

.bought-wrapper-mod__table___3xFFM .bought-wrapper-mod__no-bt___1e-OX {
  border-top: none;
}

.bought-wrapper-mod__checkbox-label___3Va60 {
  margin-left: 20px;
}

.bought-wrapper-mod__create-time___yNWVS {
  margin-right: 10px;
  font-weight: 700;
}

.bought-wrapper-mod__table___3xFFM
  .bought-wrapper-mod__thead-operations-container___2LwDA {
  padding-right: 20px;
  text-align: right;
}

.bought-wrapper-mod__seller-container___3dAK3 {
  padding: 10px 20px;
  text-align: center;
}

.sol-mod__no-br___36X3g {
  border-right: none !important;
}

.suborder-mod__production___3WebF {
  margin-left: 20px;
}

.suborder-mod__production___3WebF a:hover {
  color: #ff4200;
}

/* .bought-table-mod__table___AnaXt td {
  vertical-align: top;
} */
</style>
