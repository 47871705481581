<template>
  <div>
    <div @click="toSearch()">
      <van-sticky @click="toSearch()">
        <van-search
          @search="toSearch"
          disabled
          placeholder="请输入搜索关键词"
        />

        <!-- <van-search
          shape="round"
          placeholder="请输入搜索关键词"
          input-align="center"
          disabled
          @search="toSearch"
        /> -->
      </van-sticky>
    </div>
    <div class="classification">
      <van-tabs v-model="active" scrollspy>
        <van-tab
          v-for="(value, key, index) in catesList"
          :key="index"
          :title="value.categoryName"
        >
          <div class="goods-list">
            <div class="right">
              <div class="uni-scroll-view">
                <div class="uni-scroll-view" style="overflow: hidden auto">
                  <div class="uni-scroll-view-content">
                    <!---->

                    <div class="item" style="background-color: #ffffff">
                      <div class="title">{{ value.categoryName }}</div>

                      <van-row :gutter="20">
                        <van-col
                          :span="8"
                          v-for="(goods, key, index) in value.children"
                          :key="index"
                          @click="toProductList(goods)"
                        >
                          <div class="cata_content">
                            <div class="child">
                              <div class="goods-icon">
                                <van-image
                                  round
                                  class="goods-img"
                                  :src="goods.prcUrl"
                                />
                              </div>
                              <span class="name">
                                <span>{{ goods.categoryName }}</span>
                              </span>
                            </div>
                          </div>
                        </van-col>
                      </van-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <!-- 占位符 -->
        <van-tab disabled> </van-tab>
        <van-tab disabled> </van-tab>
        <van-tab disabled> </van-tab>
      </van-tabs>
      <div style="height: 100px">
        <!-- 占位符 -->
      </div>
    </div>

    <van-tabbar v-model="active" active-color="#ee0a24" inactive-color="#000">
      <van-tabbar-item @click="toIndex()">
        <span>首页</span>
        <template #icon>
          <img
            src="../../assets/icon/home.png"
            style="width: 24px; height: 24px"
          />
        </template>
      </van-tabbar-item>

      <van-tabbar-item to="/cate">
        <span>分类</span>
        <template #icon>
          <img
            src="../../assets/icon/fenlei.png"
            style="width: 28px; height: 28px"
          />
        </template>
      </van-tabbar-item>

      <van-tabbar-item icon="friends-o" size="30" to="/contact">
        <span>客服</span>
        <template #icon>
          <img
            src="../../assets/icon/kefu.svg"
            style="width: 24px; height: 24px"
          />
        </template>
      </van-tabbar-item>

      <van-tabbar-item icon="manager-o" size="10" to="/my">
        <span>我的</span>
        <template #icon>
          <img
            src="../../assets/icon/wode.png"
            style="width: 28px; height: 28px"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>

    <!-- 导航组件 -->
    <!-- <NavModule></NavModule> -->
  </div>
</template>
<script>
// import { catesByGoods } from "../../../api/shopping/index";
// import { Toast } from "vant";
// import { getUserInfo } from "../../../api/shopping/user";
import { mapGetters, mapState } from "vuex";
// import NavModule from "@/pages/mobile/common/navModule.vue";

export default {
  name: "mcate",
  // components: { NavModule },

  data() {
    return {
      productListStatus: false,
      active: 0,
      activeKey: 0,
      goodsList: [],
      shoppingGoods: [],
      catesList: [],
      queryParam: {
        catesType: undefined,
        catesId: undefined,
        code: undefined,
      },
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
      bannerList: (state) => state.index.bannerList,
      newList: (state) => state.index.newList,
      recommendList: (state) => state.index.recommendList,
      hotList: (state) => state.index.hotList,
      indexBrandList: (state) => state.index.indexBrandList,
    }),
  },
  created() {
    this.queryCatesList();
  },
  methods: {
    /**
     * 查询商品分类
     */
    queryCatesList() {
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list;
      });
    },

    //跳转到首页
    toIndex() {
      this.$router.push("/mindex");
    },

    // 跳转到详情页面
    toProductList(value) {
      this.$router.push({
        path: "product_list",
        query: { categoryId: value.id, categoryName: value.categoryName },
      });
    },

    clickGoods(v) {
      (this.productListStatus = false), (this.shoppingGoods = v.shoppingGoods);
      this.productListStatus = true;
    },

    // 跳转到搜索页面
    toSearch() {
      console.log("--------------");
      this.$router.push("/search");
    },
  },
};
</script>
<style>
.classification .van-tabs {
  display: flex;
  justify-content: space-between;
}
/* 左侧导航栏 */

.classification .van-tabs__nav {
  position: relative;
  display: block;
  background-color: #ffffff;
  -webkit-user-select: none;
  user-select: none;
}

.classification .van-tabs__wrap--scrollable .van-tabs__nav {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.classification .van-tabs__nav--line.van-tabs__nav--complete {
  padding-right: 0px;
  padding-left: 0px;
  position: fixed;
  left: 0;
}

.classification .van-tabs__nav--line.van-tabs__nav--complete {
  padding-right: 0px;
  padding-left: 0px;
}

.classification .van-tabs__wrap {
  height: 100%;
  width: 20%;
  overflow-y: auto;
}

.classification .van-tabs__line {
  display: none;
}

.classification .van-tab {
  position: relative;
  height: 60px;
  width: auto;
}

.classification .van-tab--active {
  color: red;
  background-color: #f6f7fc;
}

.classification .van-tab__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px; /* 根据需要设置文本容器的宽度 */
}

.classification .van-tab--active::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 16px;
  background-color: #ee0a24;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
}
/* 右侧导航栏 */

.classification .van-tabs__content {
  background-color: white;
  width: calc(100% - 90px);
  height: 100%;
  overflow-y: auto;
}

.classification {
  /* width: 23.4375rem; */
  background: #f5f7fa;
  height: 100%;
  /* position: fixed; */
  /* top: 0%; */
  /* left: 0; */
  /* overflow: hidden; */
}

.classification .goods-list {
  height: calc(100% - 70px - 5rem);
  /* display: flex; */
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.4375rem;
  margin-top: 0.15625rem;
  background: #f5f7fa;
}

.goods-list .right .item {
  width: 100%;
}

.goods-list .right .item .title {
  height: 2.5625rem;
  font-weight: 500;
  font-size: 0.875rem;
  color: #1e2331;
  display: flex;
  align-items: center;
}

.goods-list .right .item .cata_content {
  display: grid;
  grid-template-columns: repeat(4, 4.375rem);
  width: 18rem;
  min-height: 4.6875rem;
  gap: 1.40625rem/0;
  border-radius: 0.625rem 0 0 0.625rem;
  background: #fff;
  align-items: center;
  justify-items: center;
}

.goods-list .right .item .cata_content .child {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.78125rem 0;
  gap: 0.34375rem;
}

.goods-list .right .item .cata_content .child .goods-icon {
  border-radius: 50%;
  width: 2.625rem;
  height: 2.625rem;
}

.goods-list .goods-img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 100% 100%;
  border-radius: 20%;
}

/* .goods-list img {
    user-select: none;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
} */

.goods-list .right .item .cata_content .child .name {
  color: #9b9fa8;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 4rem; */
}
/* tb */
.classification .header {
  background-color: #fff;
  margin: 0 auto;
  width: 23.4375rem;
  height: 4.6875rem;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.classification .header .search-input {
  width: 22.3125rem;
  box-sizing: border-box;
  height: 2.125rem;
  background: #f0f3f7;
  border-radius: 1.0625rem;
  display: flex;
  align-items: center;
  padding: 0 0.9375rem;
}

/* left */
.classification .goods-list .left {
  width: 5rem;
  height: 100%;
  overflow: auto;
}

.classification .goods-list .left .item {
  width: 100%;
  height: 3.4375rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 0.875rem;
  color: #9b9fa8;
}

/* left */
.classification .goods-list .right {
  /* width: 18rem; */
  height: 100%;
}

.uni-scroll-view {
  position: relative;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.uni-scroll-view-content {
  width: 100%;
  height: 100%;
}

.classification .goods-list .right .item {
  width: 100%;
  padding: 0px 0px 0px 15px;
}

.classification .goods-list .right .item .title {
  height: 2.5625rem;
  font-weight: 500;
  font-size: 0.875rem;
  color: #1e2331;
  display: flex;
  align-items: center;
}

.good-item {
  flex: 0 0 33.33%;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
}
</style>
