<template>
  <div>
    <div class="support-center">
      <div class="support-search">
        <div class="suppport-title">
          <div class="st-upper">客服中心</div>
          <div class="st-sub">为您提供一站式的咨询和自助服务</div>
        </div>
      </div>
    </div>
    <div class="pc-common">
      <el-card shadow="never" style="margin-top: 20px">
        <div class="product-form">
          <!-- <div class="form-navigation">
            <div class="navigation-tag"></div>
            <div class="navigation-title">
              {{ noticeDetail.noticeTypeText }}
            </div>
          </div> -->

          <div class="form-content" style="min-height: 720px">
            <div class="notice-box">
              <div class="notice-content">
                <!-- <el-card class="box-card"> -->
                <div class="title">
                  {{ noticeDetail.contentTitle }}
                </div>

                <div class="time">
                  发布时间：{{ noticeDetail.datelineCreateReadable }}
                </div>
                <el-divider></el-divider>

                <div class="content" v-html="noticeDetail.contentDetail"></div>
                <!-- </el-card> -->
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { queryNewsDetail } from "../../api/news";

export default {
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  components: {},

  data() {
    return {
      noticeDetail: {},
      // id: undefined,
    };
  },

  watch: {
    $route(to, from) {
      console.log(to, from);
      this.getNoticeDetail(); //重新加载数据
    },
  },
  created() {
    console.log(this.id);
    this.getNoticeDetail();
  },
  methods: {
    /**
     *
     * 根据id查询公告详情
     */
    getNoticeDetail() {
      console.log(this.id);
      queryNewsDetail({
        id: this.id,
      }).then((response) => {
        this.noticeDetail = response.result;
      });
    },
  },
};
</script>
<style scoped>
.natice {
  background-color: #ffffff;
}

.product-form {
  width: 100%;
  background-color: #fff;
}

.product-form .form-navigation {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 23px;
}

.product-form .form-navigation .navigation-tag {
  width: 6px;
  height: 22px;
  background-color: #df2124;
  margin-right: 10px;
}

.product-form .form-navigation .navigation-title {
  font-size: 18px;
  font-weight: 700;
  color: #494d51;
  line-height: 60px;
  position: relative;
}

/* .product-form .form-content {
  padding: 0px 23px;
} */

.notice-box {
  /* display: flex; */
  padding-top: 10px;
}

.notice-box .notice-list {
  width: 300px;
}

.notice-box .notice-list .list-content {
  background-color: rgba(238, 238, 238, 0.38);
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 2px;
  height: 630px;
}

.notice-box .notice-list .list-content .list {
  margin-left: 50px;
  cursor: pointer;
  width: 300px;
}

.notice-box .notice-list .list-content .list .title {
  width: calc(100% - 50px);
  font-weight: 700;
  font-size: 14px;
  color: #222;
  line-height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notice-box .notice-list .list-content .checkList {
  display: flex;
  align-items: center;
  background-color: #409eff;
  border-radius: 2px;
  height: 51px;
  width: 300px;
  cursor: pointer;
}

.notice-box .notice-list .list-content .checkList .title {
  width: calc(100% - 10px);
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  line-height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notice-box .notice-list .list-content .checkList .check-tag {
  margin-left: 15px;
  margin-right: 10px;
  width: 0;
  height: 0;
  border-left: 8px solid #ffffff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.notice-box .notice-list .page {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice-box .notice-content {
  /* width: calc(100% - 380px); */
  max-height: 600px;
  overflow-y: auto;
  margin-left: 50px;
  margin-right: 30px;
}

.notice-box .notice-content .title {
  text-align: center;
  font-size: 20px;
  color: #222;
}

.notice-box .notice-content .time {
  text-align: center;
  font-size: 12px;
  color: #9598a6;
  line-height: 50px;
}

.no-data {
  min-height: 740px;
}

.support-center {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
}

.support-center .support-search {
  display: inline-block;
  width: 100%;
  height: 260px;
  background: url(../../assets/image/support-bg.png) no-repeat top;
  background-size: cover;
  text-align: center;
  position: relative;
  top: -2px;
}

.support-center .support-search .suppport-title {
  display: inline-block;
  width: 100%;
  color: #fff;
  margin: 50px 0 30px;
}

.support-center .support-search .suppport-title .st-upper {
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0;
  color: #fff;
  text-shadow: 1.35px 1.35px 0 0 #002fa3;
}

.support-center .support-search .suppport-title .st-sub {
  font-size: 20px;
  font-weight: 500;
  text-shadow: 1px 1px 0 0 rgba(0, 47, 163, 0.5);
}

.support-center .support-search .support-input-container {
  width: 900px;
  height: 50px;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 110px auto;
}

.support-center .support-search .support-input-container .support-input {
  float: left;
  width: 770px;
  height: 50px;
  outline: none;
  border: none;
  padding: 0 12px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  text-rendering: auto;
  color: #333;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  background-color: #fff;
  font-size: 16px;
}
</style>
