<template>
  <div class="credit-page">
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <div class="page-header">
          <i class="page-header-title" style="margin-right: 16px"></i>充值中心
          <!-- <el-button style="float: right; padding: 3px 0" type="text"
            >操作按钮</el-button
          > -->
        </div>
      </div>
      <Card title="充值">
        <div class="item">
          <p class="title">当前余额:</p>
          <p class="content">{{ balanceTotal }}</p>
        </div>
        <div class="item active">
          <p class="title">充值金额：</p>
          <el-input
            class="input"
            v-model="amount"
            type="Number"
            style="width: 260px"
          ></el-input>
        </div>
        <div class="item">
          <div
            class="back-btn"
            v-for="(item, index) in moneyData"
            :key="index"
            @click="changeMoney(item)"
            :class="{ active: amount == item }"
          >
            {{ item }}
          </div>
        </div>
      </Card>

      <el-card shadow="never">
        <div slot="header">
          <span style="font-size: 18px">请选择支付方式</span>
        </div>

        <div v-if="rechargeList && rechargeList.length > 0">
          <el-radio-group v-model="payType">
            <el-radio
              border
              v-for="(value, key, index) in rechargeList"
              :key="index"
              :label="value.payType"
              >{{ value.payName }}</el-radio
            >
          </el-radio-group>
          <br /><br /><br />
          <div>
            <el-button type="success" @click="handlePay">立即支付</el-button>
          </div>
        </div>

        <el-empty v-else description="商家暂时未开通在线充值"></el-empty>
      </el-card>
    </el-card>

    <Payment
      ref="paymentComponent"
      :amount="this.amount"
      :paymentMethod="this.payType"
    ></Payment>
  </div>
</template>

<script>
import Card from "@/components/card";
import { mapState } from "vuex";
import Payment from "@/components/payment/RechargePayment";

export default {
  data() {
    return {
      dialogVisible: false,
      rechargeList: {},
      moneyData: [50, 100, 200, 500],
      payType: "balance",
      amount: "",
    };
  },
  components: {
    Card,
    Payment,
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
      rechargeConfig: (state) => state.index.rechargeConfig,
    }),
  },
  created() {
    this.$store.dispatch("balance/detail");
    //初始化支付通道
    this.initPayConfig();
  },
  methods: {
    //初始化支付通道
    initPayConfig() {
      let data = {};
      this.$store.dispatch("index/queryRechargeConfig", data).then(() => {
        this.rechargeList = this.rechargeConfig.payList;
        // this.$forceUpdate();
      });
    },

    changeMoney(value) {
      this.amount = value;
    },

    /**
     * 支付订单
     */
    handlePay() {
      this.$refs.paymentComponent.toPayment();
    },

    // 清空二维码,避免生成多个二维码
    delQrcode() {
      setTimeout(() => {
        this.$refs.qrCodeDiv.innerHTML = "";
      }, 0);
    },
  },
  // 离开页面销毁轮询器
  destroyed() {
    clearInterval(this.T);
  },
};
</script>

<style scoped>
.back-img {
  height: 20px;
  margin-right: 10px;
}
.back-btn {
  border-radius: 5px;
  width: 140px;
  height: 40px;
  color: #5f5f5f;
  border: 2px solid #5f5f5f;
  line-height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 20px;
  font-size: 14px;
}
.back-btn.active {
  border: 2px solid #014785;
}
p {
  padding: 0px;
  margin: 0px;
}
.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 23px;
  color: #1b1b1b;
  opacity: 1;
}
.input-title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 23px;
  color: #1b1b1b;
  opacity: 1;
  margin-left: 10px;
}
.content {
  margin-left: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: #1b1b1b;
  opacity: 1;
}
.input {
  margin-left: 40px;
}
.item.active {
  background: #f8f8f8;
  padding: 10px 20px;
  /* padding-right: 40px;  */
  width: auto;
}

/* 支付弹框 */

.section .zf_container {
  margin: 18px auto 0;
  /* padding-top: 38px; */
  background: #fff;
  -webkit-box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  border-radius: 12px;
  text-align: center;
  padding-bottom: 38px;
}
.section .con {
  margin: 3px auto 0;
  padding-top: 10px;
  background: #fff;
  -webkit-box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  border-radius: 12px;
  text-align: center;
  padding-bottom: 38px;
}
.time span {
  font-size: 18px;
  color: #545454;
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
}
.time span p {
  color: #3259ff;
  display: inline-block;
}
.order {
  width: 340px;
  margin: 15px auto 21px;
  background: #fbfbfb;
  border-radius: 6px;
  line-height: 42px;
  text-align: center;
}
.order span:first-child {
  color: #999;
  font-size: 15px;
  margin-left: 14px;
}
.order span:nth-child(2) {
  color: #3259ff;
  font-size: 13px;
  float: right;
  margin-right: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.goods_name {
  font-weight: 500;
  font-size: 12px;
  color: #999;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 20px;
}
.goods_name span:nth-child(2) {
  margin-left: 14px;
}
.yuanmoney {
  width: 340px;
  margin: 15px auto 21px;
  background: #fbfbfb;
  border-radius: 6px;
  line-height: 42px;
  text-align: left;
}
.yuanmoney span:nth-child(2) {
  color: #3259ff;
  font-size: 13px;
  float: right;
  margin-right: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.pay_type {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.pay_type img {
  display: inline-block;
  vertical-align: middle;
  width: 23px;
}
.pay_type span {
  font-weight: 700;
  font-size: 14px;
  color: #545454;
  margin-left: 3px;
  display: inline-block;
  vertical-align: middle;
}
.code_cs,
.code {
  height: 208px;
  background: #fbfbfb;
  position: relative;
  width: 208px;
  margin-top: 10px;
  margin-left: -104px;
  left: 50%;
}
.code_cs {
  height: 208px;
  background: #ffffff;
}
.code_cs img {
  position: absolute;
  width: 49px;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px;
  padding: 10px;
}
.code {
  border: 5px solid #d8d1d1;
  border-radius: 5px;
  position: relative;
  width: 208px;
  margin-top: 10px;
  margin-left: -104px;
  left: 50%;
  display: block;
  padding: 3px;
}
.price {
  color: #386cfa;
  width: 100%;
  text-align: center;
  top: 65px;
}
.price span:first-child {
  font-size: 28px;
}
.price span {
  font-weight: 700;
}
.price span:nth-child(2) {
  font-size: 17px;
}
.price span {
  font-weight: 700;
}
.shanxinzha {
  margin-top: 32px;
  width: 100%;
  text-align: center;
}
.shanxinzha img {
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  -webkit-animation: xuanzhuan 0.8s linear infinite;
}

.shanxinzha span {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
}
.shanxinzha span p {
  display: inline-block;
  color: #386cfa;
}
.section--last {
  margin-bottom: 20px;
}
</style>
