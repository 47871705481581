<template>
  <div class="app-container">
    <el-card shadow="never">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基础配置" name="jichu">
          <el-form :model="list" label-position="top">
            <el-form-item label="网站名称">
              <el-input v-model="list.websiteName" />
            </el-form-item>

            <el-form-item label="网站简称">
              <el-input v-model="list.websiteShortName" />
            </el-form-item>

            <el-form-item label="网站描述">
              <el-input type="textarea" v-model="list.description" />
            </el-form-item>

            <el-form-item label="网站关键词">
              <el-input v-model="list.keywords" />
            </el-form-item>

            <el-form-item label="网站LOGO">
              <el-upload
                class="avatar-uploader"
                action="/api/upload/public_pic"
                list-type="picture-card"
                :headers="headers"
                :on-success="UploadOnSuccess"
                :before-upload="beforeAvatarUpload"
                :limit="1"
                :show-file-list="false"
              >
                <img
                  v-if="list.webLogo"
                  :src="list.webLogo"
                  class="avatar"
                  style="width: 100%"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>

            <el-form-item label="底部文字">
              <!-- <el-input v-model="list.beianCode" /> -->

              <editor v-model="list.beianCode"></editor>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmit">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { getBasicInfo, saveBasicInfo } from "@/api/vip";
import { Message } from "element-ui";
import { getToken, getUserId } from "@/utils/auth";
import Editor from "@/components/Editor";

export default {
  components: {
    editor: Editor,
  },
  data() {
    return {
      dialogVisible: false,

      activeName: "jichu",
      list: [],
      oldList: [],
      headers: {
        token: getToken(),
        user_id: getUserId(),
      },
    };
  },
  created() {
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.load();
      }
    },

    load() {
      var then = this;

      new Promise((resolve, rejust) => {
        getBasicInfo()
          .then((res) => {
            then.list = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },
    onSubmit() {
      var list = this.list;
      var postDatas = {};
      postDatas["websiteName"] = list.websiteName;
      postDatas["websiteShortName"] = list.websiteShortName;
      postDatas["description"] = list.description;
      postDatas["keywords"] = list.keywords;
      postDatas["webLogoId"] = list.webLogoId;
      postDatas["beianCode"] = list.beianCode;

      new Promise((resolve, rejust) => {
        saveBasicInfo(postDatas)
          .then((res) => {
            console.log(res);

            Message({
              message: "修改成功",
              type: "success",
            });
            resolve();
          })
          .catch((error) => {
            Message({
              message: "修改失败",
              type: "error",
            });
            rejust(error);
          });
      });
    },

    handleAvatarSuccess(res, file) {
      this.list.webLogoId = file.url;
    },

    beforeAvatarUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isImg) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 格式之一!");
      }
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isImg && isLt20M;
    },
    UploadOnSuccess(res) {
      this.$set(this.list, "webLogoId", res.id);
      this.$set(this.list, "webLogo", res.url);

      // this.$forceUpdate();
    },
  },
};
</script>
