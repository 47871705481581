<template>
  <div class="credit-page">
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <div class="page-header">
          <i class="page-header-title" style="margin-right: 16px"></i>余额提现
          <!-- <el-button style="float: right; padding: 3px 0" type="text"
            >操作按钮</el-button
          > -->
        </div>
      </div>
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <span style="font-size: 18px">申请提现</span>
        </div>

        <!-- <div class="item active"> -->
        <!-- <el-card shadow="never"> -->
        <el-form label-position="top">
          <el-form-item label="提现方式:">
            <div class="item">
              <div
                class="back-btn"
                v-for="(item, index) in payType"
                :key="index"
                @click="changePayType(item.title, item.value)"
                :class="{ active: valuePay == item.title }"
              >
                <img :src="item.img" alt="" class="back-img" />
                {{ item.title }}
              </div>
            </div>
          </el-form-item>

          <el-form-item label="真实姓名:">
            <el-input
              v-model="from.platformPerson"
              style="width: 500px"
            ></el-input>
          </el-form-item>

          <el-form-item label="提现账号:">
            <el-input
              v-model="from.platformAccount"
              style="width: 500px"
            ></el-input>
          </el-form-item>

          <el-form-item label="收款码:">
            <el-upload
              class="avatar-uploader"
              action="/api/upload/public_pic"
              list-type="picture-card"
              :headers="headers"
              :on-success="UploadOnSuccess"
              :before-upload="beforeAvatarUpload"
              :limit="1"
            >
              <i class="el-icon-plus" />
            </el-upload>
          </el-form-item>

          <el-form-item label="提现金额:">
            <el-input v-model="from.amount" style="width: 500px"> </el-input>
            <span style="margin-left: 10px"
              >可提现金额：{{ balanceTotal }}</span
            >
          </el-form-item>
        </el-form>
        <!-- </el-card> -->
      </el-card>
      <!-- <el-card shadow="never">
        <div class="item">
          <p class="title">可提现金额</p>
          <p class="content">{{ balanceTotal }}</p>
        </div>
        <div class="item">
          <el-input
            class="input"
            v-model="from.amount"
            style="width: 300px"
          ></el-input>

          <el-button @click.native="handleAll">全部提现</el-button>
        </div>
      </el-card> -->

      <el-button
        style="margin-top: 20px; width: 100%"
        type="warning"
        @click="handleCash"
        >立即提现</el-button
      >
    </el-card>
  </div>
</template>

<script>
import { getToken, getUserId } from "@/utils/auth";

import { mapState } from "vuex";
export default {
  inject: ["reload"],
  props: ["module"],

  data() {
    return {
      headers: {
        token: getToken(),
        user_id: getUserId(),
      },
      moneyData: [50, 100, 200, 500],
      payType: [
        {
          img: require("@/assets/image/weixin.png"),
          title: "微信",
          value: "wxpay",
        },
        {
          img: require("@/assets/image/alipay.png"),
          title: "支付宝",
          value: "alipay",
        },
      ],
      from: {
        amount: 0,
        verifyCode: null,
        verifyCodeType: 1,
        platform: "wxpay",
        platformAccount: "",
        platformPerson: "",
      },
      CodeType: [
        { name: "邮箱", id: 1 },
        { name: "手机", id: 2 },
      ],
      valuePay: "微信",
    };
  },
  components: {
    // Card,
    // Button,
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
    }),
  },
  created() {
    this.$store.dispatch("balance/detail");
  },
  methods: {
    handleCash() {
      this.$store.dispatch("withdraw/create", { ...this.from }).then(() => {
        this.$message({
          message: "已提交提现申请",
          type: "success",
        });
        this.reload();
      });
    },
    handleCode() {
      this.$store.dispatch("withdraw/code", {
        verifyCodeType: this.from.verifyCodeType,
      });
    },
    handleAll() {
      this.from.amount = this.balanceTotal;
    },
    changePayType(value, type) {
      this.valuePay = value;
      this.from.platform = type;
    },

    //上传相关代码
    UploadOnSuccess(res) {
      this.$set(this.from, "paymentCodeImg", res.id);
      this.$set(this.from, "paymentCodeImgUrl", res.url);
    },

    beforeAvatarUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isImg) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 格式之一!");
      }
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isImg && isLt20M;
    },
  },
};
</script>

<style scoped>
.back-img {
  height: 20px;
  margin-right: 10px;
}
.back-btn {
  width: 140px;
  height: 40px;
  color: #5f5f5f;
  border: 1px solid #cdcdde;
  line-height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 20px;
  font-size: 14px;
}
.back-btn.active {
  border: 2px solid #26951d;
}
p {
  padding: 0px;
  margin: 0px;
  color: #5f5f5f;
  font-size: 12px;
}
.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 23px;
  color: #1b1b1b;
  opacity: 1;
}
.content {
  margin-left: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: #1b1b1b;
  opacity: 1;
}
.input {
  margin-left: 10px;
  margin-right: 10px;
}
.item.active {
  background: #f8f8f8;
  padding: 10px 20px;
  margin-right: 40px;
  box-sizing: border-box;
  /* padding-right: 40px;  */
  /* width: auto; */
}
.input-title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 23px;
  color: #1b1b1b;
  opacity: 1;
  margin-left: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
