<template>
  <div :style="!module.base.m ? 'margin-top:0' : ''">
    <div class="banner_con">
      <!-- <div class="blur-background">
        <img :src="currentBackground" />
      </div> -->
      <!-- 内容层 -->
      <div :style="moduleBgStyle">
        <van-search
          @click="toSearch"
          shape="round"
          :background="module.moduleBg.bgColor"
          placeholder="请输入搜索关键词"
        />
        <!-- 轮播图 -->
        <van-swipe class="banner_2" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <!-- 轮播图内容 -->

            <a :href="item.clickUrl" target="_blank">
              <img
                :src="item.urlPath"
                style="width: 100%; padding: 0px 10px 0px 10px"
              />
            </a>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["module"],

  data() {
    return {
      currentBackground: "", // 默认背景
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      bannerList: (state) => state.index.bannerList,
    }),
    moduleBgStyle() {
      return {
        background: `linear-gradient(to top,  ${this.module.moduleBg.fzColor} ,${this.module.moduleBg.bgColor} 50% )`,
      };
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let data = { banType: "MOBILE" };
      this.$store.dispatch("index/queryBannerList", data).then(() => {
        this.currentBackground = this.bannerList[0].urlPath;
      });
    },
    onSwipeChange(index) {
      // 当轮播图切换时，更新背景
      this.currentBackground = this.bannerList[index].urlPath;
    },

    // 跳转到搜索页面
    toSearch() {
      this.$router.push("/search");
    },
  },
  mounted() {
    // 初始化时设置第一张轮播图的背景
    if (this.bannerList.length > 0) {
      this.currentBackground = this.bannerList[0].urlPath;
    }
  },
};
</script>
<style>
.banner_2 {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
}
</style>
