<template>
  <div class="address-page">
    <el-dialog
      title="售后详情"
      :visible.sync="dialogVisible"
      :before-close="clickClose"
      width="60%"
      center
      append-to-body
    >
      <div v-for="(item, index) in refundList" :key="index">
        <el-descriptions title="订单信息" class="margin-top" :column="2" border>
          <el-descriptions-item label="订单号">
            {{ orderFormSerialNum }}
          </el-descriptions-item>

          <el-descriptions-item label="商品名称">
            {{ item.productName }}
          </el-descriptions-item>

          <el-descriptions-item label="售后数量">
            {{ item.quantity }}
          </el-descriptions-item>

          <el-descriptions-item label="价格">
            {{ item.productPriceDeal | price }}
          </el-descriptions-item>

          <el-descriptions-item label="申请时间">
            {{ datelineCreateReadable }}
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <el-descriptions
        title="申请原因"
        style="margin-top: 50px"
        :column="1"
        border
      >
        <el-descriptions-item>
          <template slot="label"> 退货原因： </template>
          {{ reasonTypeTexts }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 问题描述： </template>
          {{ reasionDescriptions }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 退货状态： </template>
          {{ statusText }}
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions
        title="处理结果"
        style="margin-top: 50px"
        :column="1"
        border
      >
        <el-descriptions-item>
          <template slot="label"> 售后状态： </template>
          {{ statusText }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 处理备注 </template>
          {{ remark }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
// import Order from "@/components/orderStep.vue";
// import Flow from "@/components/flow.vue";
import { getToken, getUserId } from "@/utils/auth";
import { uploadPic } from "@/api/refund";
import { mapState } from "vuex";
export default {
  props: {
    afterOrderId: {
      type: Number, // 总条数
      default: 0,
    },
  },
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      id: 0,
      listObj: {},
      labelPosition: "top",
      value: 1,
      payType: [],
      desc: "",
      options: [
        { value: 1, label: "充值没到账" },
        { value: 2, label: "其他问题" },
      ],
      valuePay: "微信",
      state: 2,
      fileList: [],
      reasonImageListId: [],
      reasonImageLists: [],
      headers: {
        token: getToken(),
        user_id: getUserId(),
        // 'Content-Type':'application/octet-stream'
      },
      reasonTypeTexts: "",
      reasionDescriptions: "",
      dialogVisible: true,
    };
  },
  components: {
    // Order,
    // Flow,
  },
  computed: {
    ...mapState({
      amountFinally: (state) => state.refund.amountFinally,
      amountRefundTotal: (state) => state.refund.amountRefundTotal,
      datelineCreate: (state) => state.refund.datelineCreate,
      datelineCreateReadable: (state) => state.refund.datelineCreateReadable,
      datelineEnd: (state) => state.refund.datelineEnd,
      datelineEndReadable: (state) => state.refund.datelineEndReadable,
      deliveryPerson: (state) => state.refund.deliveryPerson,
      deliveryPhone: (state) => state.refund.deliveryPhone,
      orderFormId: (state) => state.refund.orderFormId,
      orderFormRefundProductList: (state) =>
        state.refund.orderFormRefundProductList,
      orderFormSerialNum: (state) => state.refund.orderFormSerialNum,
      reasionDescription: (state) => state.refund.reasionDescription,
      reasonImageList: (state) => state.refund.reasonImageList,
      reasonType: (state) => state.refund.reasonType,
      reasonTypeText: (state) => state.refund.reasonTypeText,
      status: (state) => state.refund.status,
      statusText: (state) => state.refund.statusText,
      remark: (state) => state.refund.remark,
      refundList: (state) => state.refund.refundList,
      productList: (state) => state.order.productList,
    }),
  },
  watch: {
    orderState: function () {
      clearInterval(this.debouncedGetAnswer);
    },
    datelineCreateReadable: function () {},
    status: function () {
      console.log(this.status);
      if (this.status == 0) {
        this.flow[1].active = true;
        this.flow[1].icon = require("@/assets/icon/refund/2-2.png");
      }
      if (this.status == 2 || this.status == 5) {
        this.flow[1].active = true;
        this.flow[2].active = true;
        this.flow[1].icon = require("@/assets/icon/refund/2-2.png");
        this.flow[2].icon = require("@/assets/icon/refund/3-2.png");
      }
      if (this.status == 3 || this.status == 4 || this.status == 1) {
        for (let i = 0; i < this.flow.length; i++) {
          this.flow[i].active = true;
          this.flow[i].icon = require("@/assets/icon/refund/" +
            (i + 1) +
            "-2.png");
        }
      }
    },
  },
  created() {
    if (this.afterOrderId) {
      this.id = this.afterOrderId;
      this.$store.dispatch("refund/detail", { id: this.id }).then(() => {
        // console.log(this.$store.state.refund)
        this.reasonImageLists = this.reasonImageList;
        this.reasonTypeTexts = this.reasonTypeText;
        this.reasionDescriptions = this.reasionDescription;
      });
    } else {
      this.state = 1;
      this.id = this.$route.query.orderid;
      this.$store.dispatch("refund/allow", { id: this.id }).then(() => {
        var arr = [];
        this.refundList.map((item) => {
          item.checked = true;
          item.sum = item.quantity;
          arr.push(item);
        });
        this.$store.commit("refund/SET_REFUND_LIST", arr);
        // console.log(this.$store.state.refund.datelineCreateReadable)
      });
    }

    // this.$forceUpdate()
  },

  mounted() {
    // console.log(this.$store.state.refund.datelineCreateReadable)
  },
  beforeDestroy() {
    clearInterval(this.debouncedGetAnswer);
  },
  methods: {
    handleGoInfo() {
      this.$router.push("/info");
    },
    submitUpload() {
      // console.log(this.$refs);
      this.$refs.upload.submit(this.listObj);
    },
    async handleUploadHttpRequest(param) {
      const fileObj = param.file;
      const response = await uploadPic(fileObj);
      if (response.status == 0) {
        console.log(response);
        this.reasonImageLists.push(response.result.url);
        this.reasonImageListId.push(response.result.id);
        // console.log(this.reasonImageLists)
      } else {
        this.$message({
          message: response.message,
          type: "error",
          duration: 5 * 1000,
        });
      }
    },
    beforeUpload(file) {
      const _self = this;
      const _URL = window.URL || window.webkitURL;
      const fileName = file.uid;
      this.listObj[fileName] = {};
      return new Promise((resolve) => {
        const img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = function () {
          _self.listObj[fileName] = {
            hasSuccess: false,
            uid: file.uid,
            width: this.width,
            height: this.height,
          };
        };
        resolve(true);
      });
    },
    handleCreate() {
      var arr = [];
      this.refundList.map((item) => {
        if (item.checked) {
          let temp = {};
          temp.orderFormProductId = item.id;
          temp.quantity = item.sum;
          arr.push(temp);
        }
      });
      this.$store
        .dispatch("refund/create", {
          orderFormProductList: arr,
          reasonType: this.value,
          reasionDescription: this.desc,
          imageIdList: this.reasonImageListId,
          id: this.id,
        })
        .then(() => {
          this.$message({
            message: "提交成功!",
            type: "success",
            duration: 5 * 200,
          });
          this.state = 2;
        });
    },
    // beforeUpload(file) {
    //     const isImg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
    //     const isLt20M = file.size / 1024 / 1024 < 20
    //     if (!isImg) {
    //         this.$message.error('上传图片只能是 JPG、PNG、GIF 格式之一!')
    //     }
    //     if (!isLt20M) {
    //         this.$message.error('上传图片大小不能超过 20MB!')
    //     }
    //     return isImg && isLt20M
    // }

    clickClose() {
      this.dialogVisible = false;
      this.$emit("close"); // 触发关闭事件，通知父组件
    },
  },
};
</script>

<style scoped>
.address-page {
  width: 100%;
  box-sizing: border-box;
  padding: 60px 100px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.info-card {
  background: #f8f8f8;
  width: 1200px;
  /* height: 200px; */
  padding: 30px 0px;
  margin-top: 20px;
}
.back {
  width: 1200px;
}
.info-title {
  /* font-size: 24px;
    font-weight: 600;
     */
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 40px;
  color: #014785;
  padding-left: 60px;
  position: relative;
}
.info-title:before {
  content: "";
  width: 4px;
  height: 34px;
  position: absolute;
  top: 4px;
  background: #014785;
  left: 44px;
}
.info-content {
  display: flex;
  margin-left: 40px;
  margin-top: 40px;
  /* flex-wrap: wrap; */
}
.content {
  margin: 0px 40px;
}
.back-btn {
  width: 200px;
  height: 46px;
  color: #5f5f5f;
  border: 2px solid #5f5f5f54;
  line-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 20px;
}
.back-btn.active {
  border: 2px solid #014785;
}
.default-p {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 29px;
  color: #5f5f5f;
  display: inline-block;
  margin-right: 20px;
}
.price-p {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 28px;
  color: #1b1b1b;
  display: inline-block;
}
.cart-list {
  display: flex;
  padding: 60px 100px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  background: #f8f8f8;
  margin-top: 60px;
}
.detail {
  display: flex;
}
.detail-img {
  height: 100px;
  width: 120px;
}
.info {
  /* margin-left: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  padding-top: 4px;
}
.title {
  margin-bottom: 40px;
}

.buy-btn {
  width: 160px;
  height: 38px;
  background: #014785;
  border: 1px solid #014785;
  line-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.p-bank {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 29px;
  color: #5f5f5f;
  display: inline-block;
}
.default-p-1 {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 29px;
  color: #5f5f5f;
  display: inline-block;
  margin: 0px;
  margin-right: 20px;
}

.cart-info {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  /* padding: 0 100px; */
  margin: 60px 0px;
}
.buy-btn-1 {
  width: 240px;
  height: 46px;
  background: #014785;
  border: 1px solid #014785;
  line-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.item {
  display: flex;
  margin-top: 10px;
}
.title {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 29px;
  color: #5f5f5f;
  width: 200px;
  text-align: left;
  margin: 0px;
  margin-bottom: 20px;
  /* margin-right: ; */
}
.content-p {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 29px;
  color: #1b1b1b;
  margin: 0px;
}
.back-img {
  height: 30px;
  margin-right: 10px;
}
.title-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.title-img {
  height: 26px;
  margin-right: 10px;
}
.title-success {
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 47px;
  color: #00a854;
}
.title-error {
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 47px;
  color: #d81e06;
}
.flow-card {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 1200px;
}
.blacks {
  margin: 0 10px;
  display: block;
  display: inline-block;
  /* float: left; */
}
.blacks:first-child {
  margin-left: 0px;

  /* display: none; */
}
.row {
  width: 100%;
  margin-left: 26px;
}
.upload-item {
  width: 100%;
  margin-top: 10px;
}
.button {
  background: #014785;
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 26px;
  color: #ffffff;
  width: 120px;
  padding: 4px 6px;
  box-sizing: border-box;
  text-align: center;
  margin-top: 10px;
  margin-left: 196px;
  /* letter-spacing: 50px; */
}

.order_pic {
  width: 70px;
  height: 70px;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}

.order_pic img {
  width: 100%;
  height: 100%;
}

._list_name_text {
  width: 218px;
  color: #2d2d2d;
  vertical-align: top;
  line-height: 24px;
  padding-top: 8px;
  padding-left: 5px;
  max-height: 96px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

._list_name_text ._list_name_text_tit {
  max-height: 50px;
  overflow: hidden;
  font-size: 14px;
}

.my-label {
  background: #e1f3d8;
  width: 100px;
}
</style>
