<!-- 首页弹框 -->
<template>
  <div>
    <el-card style="margin-top: 10px" shadow="never">
      <!-- <el-page-header>
        <template slot="extra">
          <el-button key="1" type="primary"> 保存 </el-button>
        </template>
      </el-page-header> -->

      <el-form layout="horizontal">
        <el-form-item label="是否启用">
          <el-switch
            default-checked
            :active-value="isTrue"
            :inactive-value="isFalse"
            v-model="list.status"
          />
          <div class="tongzhi_text">开启后访问首页弹出展示给用户</div>
        </el-form-item>

        <el-form-item label="公告内容">
          <!-- <quill-editor v-model="list.content" :options="editorOption">
          </quill-editor> -->

          <editor v-model="list.content" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" block @click="onSubmit"> 保存 </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Editor from "@/components/Editor";
import { getIndexNotice, saveIndexNotice } from "@/api/vip";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      list: [],
    };
  },
  components: {
    Editor,
  },
  computed: {
    ListData() {
      return this.$store.state.message.ListData;
    },
  },
  created() {
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.load();
      }
    },

    load() {
      var then = this;
      new Promise((resolve, rejust) => {
        getIndexNotice()
          .then((res) => {
            then.list = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    // 保存
    onSubmit() {
      var list = this.list;
      var postDatas = {};
      postDatas["status"] = list.status;
      postDatas["content"] = list.content;

      saveIndexNotice(postDatas)
        .then(() => {
          this.$message({
            message: "修改成功",
            type: "success",
          });
        })
        .catch(() => {
          this.$message({
            message: "修改失败",
            type: "error",
          });
        });
    },
  },
};
</script>
