<template>
  <div class="video">
    <img src="http://dh.365sup.cn/img/header.6ebf749d.png" alt="" class="cdk" />
    <div class="from_bg">
      <div class="from">
        <div class="title">
          <div class="title_bg"></div>
          <div class="title_txt">填写兑换信息</div>
        </div>

        <div
          class="center"
          style="
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
          "
        >
          <el-input v-model="carmi" placeholder="请输入兑换码"></el-input>

          <span class="btn" @click="queryConvert()">查看兑换权益</span>
        </div>
      </div>
    </div>
    <div class="rules_bg">
      <div class="rules">
        <!-- <div class="title">
          <div class="title_bg"></div>
          <div class="title_txt">兑换规则</div>
        </div> -->
        <!-- <div class="rules_list">
          <div>
            <p v-html="this.paramForm.convertDesc"></p>
          </div>
        </div> -->
        <div class="title reminder_title">
          <div class="title_bg"></div>
          <div class="title_txt">温馨提示</div>
        </div>
        <div class="rules_list reminder_list">
          <div>
            <p>（1）</p>
            <p>兑换请务必填写正确的账号；</p>
          </div>
          <div>
            <p>（2）</p>
            <p>此为虚拟直充商品，兑换成功后，不支持退换，敬请谅解；</p>
          </div>
          <div>
            <p>（3）</p>
            <p>
              此商品是系统自动兑换，以下单填写的信息为准，不能更改，如因买家填写错误造成的损失，需买家
              自行承担，敬请谅解；
            </p>
          </div>
          <!-- <div>
            <p>（4）</p>
            <p>有疑问请联系出售人</p>
          </div> -->
        </div>
      </div>
    </div>

    <!-- 兑换弹框 -->
    <van-dialog
      v-model="dialogVisible"
      show-cancel-button
      @confirm="confirm"
      confirmButtonText="确认兑换"
    >
      <div style="padding: 10px">
        <div class="dhTitle">
          {{ paramForm.convertTitle }}
        </div>

        <van-cell-group
          v-for="(item, index) in paramForm.orderParams"
          :key="index"
        >
          <van-field
            :label="item.name"
            v-model="item.value"
            :placeholder="item.desc"
          />
        </van-cell-group>

        <div class="convert_bg">
          <div class="convert_rules">
            <div class="convert_title">
              <div class="convert_title_txt">兑换规则</div>
            </div>
            <div class="convert_rules_list">
              <div>
                <p v-html="this.paramForm.convertDesc"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { queryConvertProduct, confirmConvert } from "../api/cdkey";
import { Toast } from "vant";

export default {
  data() {
    return {
      dialogVisible: false,
      paramForm: {
        convertDesc: undefined,
        convertTitle: undefined,
        orderParams: [],
        productId: undefined,
        productName: undefined,
        cardId: undefined,
      },
      carmi: undefined,
    };
  },
  created() {
    document.title = "权益兑换中心";
  },
  methods: {
    /**
     * 查询权益商品
     */
    queryConvert() {
      if (!this.carmi) {
        Toast("请输入兑换码");

        return;
      }

      let data = {
        carmi: this.carmi,
      };
      queryConvertProduct(data).then((response) => {
        if (response.status == 0) {
          this.paramForm = response.result.data;
          this.dialogVisible = true;
        }
      });
    },
    // /**
    //  * 兑换卡密
    //  */
    // cashCdkey() {
    //   console.log(this.queryParam.cdkey);
    //   if (this.queryParam.cdkey == undefined) {
    //     Toast("请输入兑换码");
    //     return;
    //   }
    //   if (this.queryParam.rechargeAccount == undefined) {
    //     Toast("请输入充值账号");
    //     return;
    //   }
    //   this.confirmStatus = true;
    // },

    /**
     * 确认兑换
     */
    confirm() {
      Toast.loading({
        message: "兑换中",
        forbidClick: true,
      });
      confirmConvert(this.paramForm).then((response) => {
        if (response.status == 0) {
          Toast.success(response.result.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.video {
  background: #5b46ef;
  height: 100vh;
}

.video .cdk {
  width: 100%;
  height: 62.267vw;
  content: "viewport-units-buggyfill; width: 100vw; height: 62.267vw";
}

.video .from_bg {
  margin: 0 auto;
  width: 96.933vw;
  background: rgba(137, 199, 252, 0.3);
  border-radius: 1.333vw;
  padding-top: 1.467vw;
  padding-bottom: 2.133vw;
  content: "viewport-units-buggyfill; width: 96.933vw; border-radius: 1.333vw; padding-top: 1.467vw; padding-bottom: 2.133vw";
}

.video .from_bg .from {
  margin: 0 auto;
  width: 94vw;
  background: #fff;
  border-radius: 1.333vw;
  padding-bottom: 5.067vw;
  content: "viewport-units-buggyfill; width: 94vw; border-radius: 1.333vw; padding-bottom: 5.067vw";
}

.video .from_bg .from .title {
  position: relative;
  padding-left: 30vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: 4.267vw;
  height: 16vw;
  content: "viewport-units-buggyfill; padding-left: 30vw; top: 4.267vw; height: 16vw";
}

.video .from_bg .from .title .title_bg {
  position: absolute;
  width: 34vw;
  height: 3.333vw;
  background: #dfe9ff;
  top: 3.333vw;
  content: "viewport-units-buggyfill; width: 34vw; height: 3.333vw; top: 3.333vw";
}

.video .from_bg .from .title .title_txt {
  position: absolute;
  left: 32.667vw;
  font-size: 4.8vw;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 700;
  color: #4b68ef;
  background: linear-gradient(0deg, #5b46ef, #3c86ee);
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  content: "viewport-units-buggyfill; left: 32.667vw; font-size: 4.8vw";
}

.video .from_bg .message .input {
  position: relative;
}

.video .from_bg .message .input .tips {
  width: 74.267vw;
  height: 13.067vw;
  background: #eff6ff;
  border-radius: 1.333vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2.133vw 1.333vw 1.333vw 4.4vw;
  margin-left: 9.867vw;
  font-size: 3.467vw;
  font-family: Hiragino Sans GB;
  font-weight: 400;
  color: #ff9727;
  text-align: left;
  content: "viewport-units-buggyfill; width: 74.267vw; height: 13.067vw; border-radius: 1.333vw; padding: 2.133vw 1.333vw 1.333vw 4.4vw; margin-left: 9.867vw; font-size: 3.467vw";
}

.video .from_bg .message .input .code {
  width: 5.333vw;
  height: 5.2vw;
  position: absolute;
  left: 11.867vw;
  top: 5.867vw;
  padding-right: 3.867vw;
  border-right: 0.267vw solid #618ef1;
  content: "viewport-units-buggyfill; width: 5.333vw; height: 5.2vw; left: 11.867vw; top: 5.867vw; padding-right: 3.867vw; border-right: 0.267vw solid #618ef1";
}

.video .from_bg .message .input .number {
  top: 5.733vw;
  left: 12.533vw;
  padding-right: 3.467vw;
  width: 5.2vw;
  height: 5.867vw;
  content: "viewport-units-buggyfill; top: 5.733vw; left: 12.533vw; padding-right: 3.467vw; width: 5.2vw; height: 5.867vw";
}

.video .from_bg .message .input .verification_code {
  width: 4.667vw;
  height: 5.867vw;
  left: 12.533vw;
  content: "viewport-units-buggyfill; width: 4.667vw; height: 5.867vw; left: 12.533vw";
}

.video .from_bg .message .input .form_code {
  margin-top: 2.4vw;
  margin-bottom: 2vw;
  width: 81.067vw;
  height: 12.8vw;
  border-radius: 6.933vw;
  font-size: 4.267vw;
  outline: none;
  padding-left: 18.667vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 0.267vw;
  border: 0.267vw solid #618ef1;
  -webkit-appearance: none;
  content: "viewport-units-buggyfill; margin-top: 2.4vw; margin-bottom: 2vw; width: 81.067vw; height: 12.8vw; border-radius: 6.933vw; font-size: 4.267vw; padding-left: 18.667vw; padding-top: 0.267vw; border: 0.267vw solid #618ef1";
}

.video .from_bg .message .input .form_code::-webkit-input-placeholder {
  color: #b9b9b9;
}

.video .from_bg .message .input .form_code::-moz-placeholder {
  color: #b9b9b9;
}

.video .from_bg .message .input .form_code::-ms-input-placeholder {
  color: #b9b9b9;
}

.video .from_bg .message .input .form_code::placeholder {
  color: #b9b9b9;
}

.video .from_bg .message .input .verification {
  width: 48vw;
  margin-left: -33.867vw;
  content: "viewport-units-buggyfill; width: 48vw; margin-left: -33.867vw";
}

.video .from_bg .message .input .card_img {
  position: absolute;
  width: 29.467vw;
  height: 13.333vw;
  top: 2vw;
  right: 7.333vw;
  content: "viewport-units-buggyfill; width: 29.467vw; height: 13.333vw; top: 2vw; right: 7.333vw";
}

.video .rules_bg {
  margin: 2.8vw auto 0;
  width: 96.933vw;
  background: rgba(137, 199, 252, 0.3);
  padding-top: 1.733vw;
  padding-bottom: 2.4vw;
  content: "viewport-units-buggyfill; margin-top: 2.8vw; width: 96.933vw; border-radius: 1.333vw; padding-top: 1.733vw; padding-bottom: 2.4vw";
}

.video .rules_bg .rules,
.video .rules_bg {
  border-radius: 1.333vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.video .rules_bg .rules {
  margin: 0 auto;
  width: 94vw;
  background: #fff;
  padding-bottom: 2.933vw;
  content: "viewport-units-buggyfill; width: 94vw; padding-bottom: 2.933vw; border-radius: 1.333vw";
}

.video .rules_bg .rules .title {
  position: relative;
  padding-left: 35.867vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: 4.8vw;
  height: 16vw;
  content: "viewport-units-buggyfill; padding-left: 35.867vw; top: 4.8vw; height: 16vw";
}

.video .rules_bg .rules .title .title_bg {
  position: absolute;
  width: 22.267vw;
  height: 3.333vw;
  background: #dfe9ff;
  top: 3.333vw;
  content: "viewport-units-buggyfill; width: 22.267vw; height: 3.333vw; top: 3.333vw";
}

.video .rules_bg .rules .title .title_txt {
  position: absolute;
  left: 37.333vw;
  font-size: 4.8vw;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 700;
  color: #4b68ef;
  background: linear-gradient(0deg, #5b46ef, #3c86ee);
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  content: "viewport-units-buggyfill; left: 37.333vw; font-size: 4.8vw";
}

.video .rules_bg .rules .reminder_title {
  top: 4vw;
  content: "viewport-units-buggyfill; top: 4vw";
}

.video .rules_bg .rules .rules_list {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2vw 5.6vw 0;
  content: "viewport-units-buggyfill; padding: 2vw 5.6vw 0px 5.6vw";
}

.video .rules_bg .rules .rules_list div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.video .rules_bg .rules .rules_list p {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  text-align: left;
  margin-bottom: 2.8vw;
  content: "viewport-units-buggyfill; font-size: 3.2vw; margin-bottom: 2.8vw";
}

.video .rules_bg .rules .reminder_list {
  padding-top: 0;
}

.video .link {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 4vw 18.267vw 6.667vw;
  position: relative;
  content: "viewport-units-buggyfill; padding: 4vw 18.267vw 6.667vw 18.267vw";
}

.video .link .link_btn,
.video .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.video .link .link_btn {
  border: none;
  width: 29.333vw;
  height: 11.733vw;
  background: linear-gradient(0deg, #3c9bf3, #3fb3ff);
  border-radius: 5.867vw;
  text-decoration: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  content: "viewport-units-buggyfill; width: 29.333vw; height: 11.733vw; border-radius: 5.867vw";
}

.video .link .link_btn .link_icon {
  width: 6.133vw;
  height: 6.133vw;
  margin-right: 0.667vw;
  content: "viewport-units-buggyfill; width: 6.133vw; height: 6.133vw; margin-right: 0.667vw";
}

.video .link .link_btn .link_text,
.video .link .link_btn .link_texts .link_texts_top {
  font-size: 4vw;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  content: "viewport-units-buggyfill; font-size: 4vw";
}

.video .link .link_btn .link_texts .link_texts_top {
  display: block;
}

.video .link .link_btn .link_texts .link_texts_bottom {
  display: block;
  font-size: 2.533vw;
  font-family: Mont;
  color: #fff;
  content: "viewport-units-buggyfill; font-size: 2.533vw";
}

.video .mask {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.video .mask_coent {
  position: fixed;
  z-index: 2;
  width: 88.8vw;
  height: 64.8vw;
  background: #fff;
  border-radius: 2.667vw;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "viewport-units-buggyfill; width: 88.8vw; height: 64.8vw; border-radius: 2.667vw";
}

.video .mask_coent p {
  height: 16.267vw;
  line-height: 16.267vw;
  background: #089efd;
  border-radius: 2.667vw 2.667vw 0 0;
  font-size: 4.267vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  content: "viewport-units-buggyfill; height: 16.267vw; line-height: 16.267vw; border-radius: 2.667vw 2.667vw 0px 0px; font-size: 4.267vw";
}

.video .mask_coent .input {
  position: relative;
  top: 5.333vw;
  content: "viewport-units-buggyfill; top: 5.333vw";
}

.video .mask_coent .input .code {
  width: 5.333vw;
  height: 5.2vw;
  position: absolute;
  left: 11.467vw;
  top: 5.867vw;
  padding-right: 3.867vw;
  border-right: 0.267vw solid #618ef1;
  content: "viewport-units-buggyfill; width: 5.333vw; height: 5.2vw; left: 11.467vw; top: 5.867vw; padding-right: 3.867vw; border-right: 0.267vw solid #618ef1";
}

.video .mask_coent .input .form_code {
  margin-top: 2.4vw;
  margin-bottom: 2vw;
  width: 81.067vw;
  height: 12.8vw;
  border-radius: 6.933vw;
  outline: none;
  padding-left: 18.667vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.267vw solid #618ef1;
  content: "viewport-units-buggyfill; margin-top: 2.4vw; margin-bottom: 2vw; width: 81.067vw; height: 12.8vw; border-radius: 6.933vw; padding-left: 18.667vw; border: 0.267vw solid #618ef1";
}

.video .mask_coent .input .form_code::-webkit-input-placeholder {
  color: #b9b9b9;
}

.video .mask_coent .input .form_code::-moz-placeholder {
  color: #b9b9b9;
}

.video .mask_coent .input .form_code::-ms-input-placeholder {
  color: #b9b9b9;
}

.video .mask_coent .input .form_code::placeholder {
  color: #b9b9b9;
}

.video .mask_coent .input .verification {
  width: 47.467vw;
  margin-left: -33.867vw;
  content: "viewport-units-buggyfill; width: 47.467vw; margin-left: -33.867vw";
}

.video .mask_coent .input .card_img {
  position: absolute;
  width: 29.467vw;
  height: 13.333vw;
  top: 2vw;
  right: 7.333vw;
  content: "viewport-units-buggyfill; width: 29.467vw; height: 13.333vw; top: 2vw; right: 7.333vw";
}

.video .mask_coent .mask_btn {
  width: 81.067vw;
  font-size: 4.267vw;
  margin: 9.333vw auto 0;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  height: 13.867vw;
  line-height: 13.867vw;
  background: linear-gradient(0deg, #3c9bf3, #3fb3ff);
  border-radius: 6.933vw;
  content: "viewport-units-buggyfill; width: 81.067vw; font-size: 4.267vw; margin-top: 9.333vw; height: 13.867vw; line-height: 13.867vw; border-radius: 6.933vw";
}

.btn {
  display: block;
  width: 80%;
  height: 40px;
  line-height: 25px;
  background: url(http://meihu-sales.oss-cn-chengdu.aliyuncs.com/btn_bg.png) 50%
    center / cover;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  color: rgb(120, 52, 7);
  margin-top: 20px;
  text-align: center;
}

.dhTitle {
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.convert_bg {
  margin: 2.8vw auto 0;

  padding-top: 25px;
  padding-bottom: 2.4vw;
}

.convert_bg .convert_rules {
  padding: 10px;
  margin: 0 auto;
  /* width: 94vw; */
  background: #fff;
  padding-bottom: 2.933vw;
  /* content: "viewport-units-buggyfill; width: 94vw; padding-bottom: 2.933vw; border-radius: 1.333vw"; */
}

.convert_bg .convert_rules .convert_title {
  position: relative;
  /* padding-left: 35.867vw; */
  box-sizing: border-box;
  /* top: 4.8vw; */
  /* height: 16vw; */
  content: "viewport-units-buggyfill; padding-left: 35.867vw; top: 4.8vw; height: 16vw";
  display: flex;
  justify-content: center;
  align-items: center;
}

.convert_bg .convert_rules .convert_title .convert_title_txt {
  position: absolute;
  /* left: 37.333vw; */
  /* font-size: 4.8vw; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 700;
  color: #2a1a98;
  background: linear-gradient(0deg, #5b46ef, #3c86ee);
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  content: "viewport-units-buggyfill; left: 37.333vw; font-size: 4.8vw";
}

.convert_bg .convert_rules .convert_rules_list {
  box-sizing: border-box;
  margin-top: 20px;
  content: "viewport-units-buggyfill; padding: 2vw 5.6vw 0px 5.6vw";
}
</style>
