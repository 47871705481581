<template>
  <div class="contact">
    <!-- <van-nav-bar title="联系客服" /> -->

    <div class="summary">
      <div class="logo" style="text-align: center">
        <img style="width: 100%" src="../../assets/image/tel-bg.png" />
      </div>
    </div>

    <div class="dx2-service">
      <div
        class="dx2-service-card"
        v-for="(item, index) in configList"
        :key="index"
      >
        <van-row gutter="5">
          <a :href="item.link" target="_blank">
            <van-col span="4">
              <img
                class="nut-icon__img"
                :src="item.icon"
                style="font-size: 50px; width: 50px; height: 50px"
              />
            </van-col>
            <van-col span="16">
              <div class="dx2-service-card-title-name">{{ item.label }}</div>
              <div class="dx2-service-card-title-desc">
                {{ item.value }}
              </div></van-col
            >
          </a>

          <van-col span="4">
            <div class="nut-image" style="width: 60px; height: 60px">
              <img
                class="nut-img"
                :src="item.uploadImg"
                alt=""
                style="object-fit: fill; object-position: center center"
                @click="handleImageClick(item.uploadImg)"
              />
            </div>
          </van-col>
        </van-row>

        <!-- <div class="dx2-service-card-icon">
          <img
            class="nut-icon__img"
            :src="item.icon"
            style="font-size: 50px; width: 50px; height: 50px"
          />
        </div>
        <a :href="item.link" target="_blank">
          <div class="dx2-service-card-title">
            <div class="dx2-service-card-title-name">{{ item.label }}</div>
            <div class="dx2-service-card-title-desc">
              {{ item.value }}
            </div>
          </div>
        </a>
        <div class="dx2-service-card-link">
          <div class="nut-image" style="width: 60px; height: 60px">
            <img
              class="nut-img"
              :src="item.uploadImg"
              alt=""
              style="object-fit: fill; object-position: center center"
              @click="handleImageClick(item.uploadImg)"
            />
          </div>
        </div> -->
      </div>

      <!-- <div class="dx2-service-card">
        <div class="dx2-service-card-icon">
          <img
            class="nut-icon__img"
            src="../../assets/image/wx.svg"
            style="font-size: 50px; width: 50px; height: 50px"
          />
          <div
            class="nut-divider nut-divider-vertical dx2-service-card-icon-divider"
          ></div>
        </div>
        <div class="dx2-service-card-title">
          <div class="dx2-service-card-title-name">官方微信</div>
          <div class="dx2-service-card-title-desc">
            {{ this.wechatNum }}
          </div>
        </div>
        <div class="dx2-service-card-link">
          <div class="nut-image" style="width: 60px; height: 60px">
            <img
              class="nut-img"
              :src="wechatQrCodeImg"
              alt=""
              style="object-fit: fill; object-position: center center"
              @click="handleImageClick(wechatQrCodeImg)"
            />
          </div>
        </div>
      </div> -->
      <!--
      <div class="dx2-service-card" v-if="this.qqNum">
        <div class="dx2-service-card-icon">
          <img
            class="nut-icon__img"
            src="../../assets/image/qq.svg"
            style="font-size: 50px; width: 50px; height: 50px"
          />
          <div
            class="nut-divider nut-divider-vertical dx2-service-card-icon-divider"
          ></div>
        </div>
        <div class="dx2-service-card-title">
          <div class="dx2-service-card-title-name">官方QQ</div>
          <div class="dx2-service-card-title-desc">
            {{ this.qqNum }}
          </div>
        </div>
        图片
        <div class="dx2-service-card-link">
          <div class="nut-image" style="width: 60px; height: 60px">
            <img
              class="nut-img"
              :src="qqQrCodeImg"
              alt=""
              style="object-fit: fill; object-position: center center"
              @click="handleImageClick(qqQrCodeImg)"
            />
          </div>
        </div>
      </div> -->
    </div>
    <!-- <van-empty v-if="!this.contactQQ && !this.contactWeChat && !this.contactPhone" description="商家暂未配置客服" /> -->

    <!-- 底部栏 -->
    <van-tabbar active-color="#ee0a24" inactive-color="#000">
      <!-- <van-tabbar-item icon="wap-home-o" @click="toIndex">首页</van-tabbar-item>
      <van-tabbar-item icon="friends" @click="toContact">客服</van-tabbar-item>
      <van-tabbar-item icon="manager-o" @click="toMy">我的</van-tabbar-item> -->

      <van-tabbar-item @click="toIndex()">
        <span>首页</span>
        <template #icon>
          <img
            src="../../assets/icon/home.png"
            style="width: 24px; height: 24px"
          />
        </template>
      </van-tabbar-item>

      <van-tabbar-item to="/cate">
        <span>分类</span>
        <template #icon>
          <img
            src="../../assets/icon/fenlei.png"
            style="width: 24px; height: 24px"
          />
        </template>
      </van-tabbar-item>

      <van-tabbar-item icon="friends-o" size="30" to="/contact">
        <span>客服</span>
        <template #icon>
          <img
            src="../../assets/icon/kefu.svg"
            style="width: 28px; height: 28px"
          />
        </template>
      </van-tabbar-item>

      <van-tabbar-item icon="manager-o" size="10" to="/my">
        <span>我的</span>
        <template #icon>
          <img
            src="../../assets/icon/wode.png"
            style="width: 24px; height: 24px"
          /> </template
      ></van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { Toast } from "vant";
import { ImagePreview } from "vant";

export default {
  name: "contact",
  data() {
    return {
      // contactQQ: "",
      // contactWeChat: "",
      // contactQRUrl: "",
      // contactPhone: "",
      phoneNum: "",
      qqNum: "",
      qqQrCode: null,
      qqQrCodeImg: "",
      wechatNum: "",
      wechatQrCode: null,
      wechatQrCodeImg: "",
      configList: [],
    };
  },
  computed: {
    ...mapGetters(["token"]),
    ...mapState({
      customerDetail: (state) => state.index.customerDetail,
    }),
  },
  created() {
    document.title = "联系客服";

    this.reload();
  },
  methods: {
    //页面加载
    reload() {
      let data = {};
      this.$store.dispatch("index/getCustomerSetting", data).then(() => {
        this.configList = this.customerDetail.result;

        // this.phoneNum = this.customerDetail.result.phoneNum;
        // this.qqNum = this.customerDetail.result.qqNum;
        // this.qqQrCode = this.customerDetail.result.qqQrCode;
        // this.qqQrCodeImg = this.customerDetail.result.qqQrCodeImg;
        // this.wechatNum = this.customerDetail.result.wechatNum;
        // this.wechatQrCode = this.customerDetail.result.wechatQrCode;
        // this.wechatQrCodeImg = this.customerDetail.result.wechatQrCodeImg;
      });
    },

    copyWeChat() {
      // if (this.contactWeChat == null || this.contactWeChat == "") {
      //   Toast.fail({
      //     message: "商家未配置微信客服",
      //   });
      // }
      const input = document.createElement("input");
      input.setAttribute("value", this.contactWeChat);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      Toast.success({
        message: "官方微信已复制",
      });
    },

    copyQQ() {
      // if (this.contactQQ == null || this.contactQQ == "") {
      //   Toast.fail({
      //     message: "商家未配置QQ客服",
      //   });
      // }
      const input = document.createElement("input");
      input.setAttribute("value", this.contactQQ);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      Toast.success({
        message: "官方QQ已复制",
      });
    },

    /**
     * 点击放大图片
     */
    handleImageClick(img) {
      const images = [img];
      ImagePreview({ images });
    },

    //跳转到首页
    toIndex() {
      this.$router.push("/mindex");
    },
  },
};
</script>

<style scoped>
.contact {
  width: 100%;
  /* background-color: #fff; */
  height: 100vh;
}
.dx2-service-card {
  border-radius: 6px;
  padding: 12px;
  margin: 12px;
  background-color: #fff;
}

.dx2-service-card-icon {
  /* display: flex; */
  /* -webkit-box-orient: horizontal; */
  /* -webkit-box-direction: normal; */
  /* -ms-flex-direction: row; */
  /* flex-direction: row; */
  margin: 0 10px 0 0;
  /* -webkit-box-align: center; */
  /* -ms-flex-align: center; */
  /* align-items: center; */
}

.nut-icon {
  display: inline-block;
  position: relative;
  /* width: var(--nut-icon-width);
    height: var(--nut-icon-height); */
  line-height: var(--nut-icon-line-height);
  text-align: right;
}

.dx2-service-card-icon-divider {
  height: 30px;
}

.nut-divider.nut-divider-vertical {
  position: relative;
  top: var(--nut-divider-vertical-top, 2px);
  display: inline-block;
  /* height: var(--nut-divider-vertical-height, 12px); */
  border-left: 1px solid
    var(--nut-divider-vertical-border-left, rgba(0, 0, 0, 0.06));
  margin: var(--nut-divider-vertical-margin, 0 8px);
}

.nut-divider {
  /* display: flex; */
  align-items: center;
  font-size: var(--nut-divider-text-font-size, var(--nut-font-size-2, 14px));
  color: var(--nut-divider-text-color, #909ca4);
  /* margin: var(--nut-divider-margin, 16px 0); */
}

.dx2-service-card-title {
  /* display: -webkit-box; */
  /* display: -ms-flexbox; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /* -ms-flex-direction: column; */
  flex-direction: column;
  -webkit-box-flex: 1;
  /* -ms-flex: 1; */
  flex: 1;
}

.dx2-service-card-title-name {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
}

.dx2-service-card-title-desc {
  font-size: 12px;
  color: #666;
}

.dx2-service-card-link {
  display: flex;
  margin-left: 4px;
  flex-direction: row;
  align-items: center;
}

.nut-image {
  display: block;
  position: relative;
}

.nut-image .nut-img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
