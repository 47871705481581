<template>
  <div class="balance-page">
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <div class="page-header">
          <i class="page-header-title" style="margin-right: 16px"></i>资金明细
          <!-- <el-button style="float: right; padding: 3px 0" type="text"
            >操作按钮</el-button
          > -->
        </div>
      </div>
      <el-descriptions class="custom-descriptions" :column="3" border>
        <el-descriptions-item label="可用金额">
          <span class="highlight-text">{{ balanceTotal }}</span>
        </el-descriptions-item>

        <el-descriptions-item label="累计充值">
          <span class="highlight-text">{{ totalRechargeSuccess }}</span>
        </el-descriptions-item>

        <el-descriptions-item label="冻结金额">
          <span class="highlight-text">{{ totalWithdrawRejected }}</span>
        </el-descriptions-item>

        <el-descriptions-item label="累计提现">
          <span class="highlight-text">{{ totalWithdrawSuccess }}</span>
        </el-descriptions-item>

        <el-descriptions-item label="可提现">
          <span class="highlight-text">{{ balanceCanWithdraw }}</span>
        </el-descriptions-item>

        <el-descriptions-item label="提现中">
          <span class="highlight-text">{{ totalWithdrawing }}</span>
        </el-descriptions-item>
      </el-descriptions>

      <el-divider>资金明细</el-divider>

      <el-table
        border
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column property="serialNum" label="订单号" align="center" />
        <el-table-column property="eventText" label="交易类型" align="center" />
        <el-table-column property="amount" label="交易金额" align="center" />
        <el-table-column property="typeText" label="收支类型" align="center" />
        <el-table-column property="remarks" label="备注" align="center" />

        <el-table-column
          property="datelineReadable"
          label="交易时间"
          align="center"
        />
      </el-table>

      <!-- 分页 -->
      <div style="float: right; padding: 20px">
        <pagination
          :total="total"
          :current-page="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          @currentChange="handleCurrentChange"
          @editPagesizes="editPageSizes"
        />
      </div>

      <!-- <Card title="收支明细">
      <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column property="datelineReadable" label="时间" width="320" />
        <el-table-column property="amount" label="金额" width="180" />
        <el-table-column property="typeText" label="类型" width="180" />
        <el-table-column property="eventText" label="事件" />
      </el-table>
    </Card> -->
    </el-card>
  </div>
</template>

<script>
import { list } from "@/api/balance";

import { mapState } from "vuex";
export default {
  data() {
    return {
      itemData: [
        [
          { icon: require("@/assets/icon/1.png"), title: "可用金额", num: 0 },
          { icon: require("@/assets/icon/1.png"), title: "累计充值", num: 0 },
        ],
        [
          { icon: require("@/assets/icon/1.png"), title: "冻结金额", num: 0 },
          { icon: require("@/assets/icon/1.png"), title: "累计提现", num: 0 },
        ],
        [
          { icon: require("@/assets/icon/1.png"), title: "可提现", num: 0 },
          { icon: require("@/assets/icon/1.png"), title: "提现中", num: 0 },
        ],
      ],
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  components: {
    // Card,
    // Button,
  },
  filters: {
    price(value) {
      return "￥" + value;
    },
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      totals: (state) => state.balance.totals,
      statusText: (state) => state.user.statusText,
    }),
  },
  created() {
    this.$store.dispatch("user/info");
    this.$store.dispatch("balance/detail").then(() => {
      this.itemData[0][0].num = this.balanceTotal;
      this.itemData[0][1].num = this.totalRechargeSuccess;
      this.itemData[1][0].num = this.totalWithdrawRejected;
      this.itemData[1][1].num = this.totalWithdrawSuccess;
      this.itemData[2][0].num = this.balanceCanWithdraw;
      this.itemData[2][1].num = this.totalWithdrawing;
    });
    this.loadData();
  },
  methods: {
    goCredit() {
      let mainRoute = this.$route.path.split("/")[1];
      let subRoute;

      if (mainRoute === "balance") {
        subRoute = "credit";
      } else if (mainRoute === "balance2") {
        subRoute = "credit2";
      } else if (mainRoute === "balance3") {
        subRoute = "credit3";
      } else {
        return;
      }
      this.$router.push(subRoute);
    },

    /**
     * 查询列表
     */
    loadData() {
      new Promise((resolve, rejust) => {
        list(this.queryParams)
          .then((res) => {
            this.tableData = res.result.list;
            this.total = res.result.total;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum;
      this.loadData();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParams.pageSize = val;
      this.loadData();
    },

    goCash() {
      let mainRoute = this.$route.path.split("/")[1];
      let subRoute;

      if (mainRoute === "balance") {
        subRoute = "cash";
      } else if (mainRoute === "balance2") {
        subRoute = "cash2";
      } else if (mainRoute === "balance3") {
        subRoute = "cash3";
      } else {
        return;
      }
      this.$router.push(subRoute);
    },
  },
};
</script>

<style scoped>
.custom-descriptions .el-descriptions-item__label {
  font-weight: bold;
  color: #333;
}

.custom-descriptions .el-descriptions-item__content {
  color: #666;
}

.custom-descriptions .el-descriptions-item {
  padding: 8px 0;
}

.custom-descriptions .el-descriptions__item {
  border-bottom: 1px solid #eee;
}

.highlight-text {
  color: red;
  font-weight: bold;
}
</style>
